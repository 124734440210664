import React, { useEffect, useState } from 'react';
import FlashMessage from './FlashMessage';
import Bus from '../../../Lib/misc/Bus';

const FlashMessages = () => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    Bus.addListener('flash', ({ message }) => {
      if (message.titre) {
        const newId = (new Date().getTime() + Math.floor((Math.random() * 10000) + 1)).toString(16);
        setMessages([...messages, { ...message, id: newId }]);
      }
    });
  }, []);

  return (
    <div id="flash">
      {messages.map((message) => (
        <FlashMessage
          key={message.id}
          message={message}
        />
      ))}
    </div>
  );
};

export default FlashMessages;
