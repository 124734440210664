import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import Button from './Button';

const NavButton = ({
  children, to, small, variant, className, icon, disabled, ...props
}) => {
  if (disabled) {
    return (
      <Button
        disabled
        className={clsx(
          'stdBtn',
          small && 'stdBtn-small',
          variant && variant.split(' ').map((el) => `stdBtn-${el}`),
          className,
        )}
        {...props}
      > {icon && (
      <i className={`a-${icon}`} />
      )}
        {children}
      </Button>
    );
  }
  return (
    <NavLink
      to={to}
      className={clsx(
        'stdBtn',
        small && 'stdBtn-small',
        variant && variant.split(' ').map((el) => `stdBtn-${el}`),
        className,
      )}
      {...props}
    >
      {icon && (
        <i className={`a-${icon}`} />
      )}
      {children}
    </NavLink>
  );
};

NavButton.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  variant: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  small: PropTypes.bool,
  disabled: PropTypes.bool,
};

NavButton.defaultProps = {
  variant: null,
  icon: null,
  className: '',
  children: null,
  small: false,
  disabled: false,
};

export default NavButton;
