import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { DateTime } from 'luxon';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import Popup from '../../components/UI/Popup/Popup';
import useInterface from '../../Lib/useInterface';
import Fieldset from '../../components/UI/Form/Fieldset';
import Button from '../../components/UI/Button';
import InputDate from '../../components/UI/Form/InputDate';
import EvenementState from '../../Lib/EvenementState';
import EditEvenementDatePopup from './EditEvenementDatePopup';
import * as StateAction from '../../Redux/actions/State';
import ConfirmationPopup from '../../components/UI/ConfirmationPopup';

const StaffEvenementDiv = () => {
  const dispatch = useDispatch();
  const evenement = useSelector((state) => state.stateDataReducer.evenement);
  const { sendRestErrorMessage, sendMessage } = useInterface();
  const [showEditDatePopup, setShowEditDatePopup] = useState(null);
  const [showConfirmPopup, setShowConfirmPopup] = useState(null);

  const toggleEvent = async () => {
    try {
      const values = {
        isFinished: showConfirmPopup,
      };
      const result = await axios.put('/staff/config', values);
      dispatch({ type: StateAction.SET_CONFIG, config: result.data });

      sendMessage(isFinished ? 'The event has been stopped' : 'The event has been started');
    } catch (err) {
      sendRestErrorMessage(err);
    }
  };

  const isFinished = parseInt(useSelector((state) => state.stateDataReducer.config.isFinished));
  if (evenement && evenement.date) {
    const luxonDate = DateTime.fromISO(evenement.date).setZone(process.env.TIMEZONE);
    return (
      <>
        <div className={clsx('stdStaffSpace-top-evenementInfos', isFinished === EvenementState.FINISHED && 'stdStaffSpace-top-evenementInfos-finished')}><strong>
          {isFinished === EvenementState.FINISHED && 'Finished - '}
          {isFinished === EvenementState.UNSTARTED && 'Unstarted - '}
          Evenement
        </strong> : <button type="button" onClick={() => setShowEditDatePopup(true)}>{luxonDate.toFormat('DDD')} ({evenement.duree} days)</button>
          <div>
          { isFinished === EvenementState.UNSTARTED && <button type="button" onClick={() => setShowConfirmPopup(EvenementState.STARTED)}>Start event</button> }
          { isFinished === EvenementState.FINISHED && <button type="button" onClick={() => setShowConfirmPopup(EvenementState.STARTED)}>Resume event</button> }
          { isFinished === EvenementState.STARTED && <><button type="button" onClick={() => setShowConfirmPopup(EvenementState.UNSTARTED)}>Stop event</button> / <button type="button" onClick={() => setShowConfirmPopup(EvenementState.FINISHED)}>Finish event</button></> }
          </div>
        </div>
        {showEditDatePopup && (
          <EditEvenementDatePopup
            evenement={evenement}
            onCancel={() => setShowEditDatePopup(false)}
            onConfirm={(newEvenement) => {
              setShowEditDatePopup(false);
              dispatch({ type: StateAction.SET_EVENEMENT, evenement: newEvenement });
            }}
          />
        )}
        { showConfirmPopup !== null && (
          <ConfirmationPopup
            texte={(() => {
              switch (showConfirmPopup) {
                case EvenementState.FINISHED: {
                  return 'To stop the event ?';
                }
                case EvenementState.STARTED: {
                  return 'To start the event ?';
                }
                default:
                case EvenementState.UNSTARTED: {
                  return 'To unstart the event ?';
                }
              }
            })()}
            onCancel={() => setShowConfirmPopup(null)}
            onConfirm={() => {
              setShowConfirmPopup(null);
              toggleEvent();
            }}
          />
        ) }
      </>
    );
  }
  return null;
};

StaffEvenementDiv.propTypes = {
};

export default StaffEvenementDiv;
