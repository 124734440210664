import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Portal from './Portal';

const ClicPortal = ({
  children, onOpen, portalComponent, setOnCloseCallback, ...props
}) => {
  const [show, setShow] = useState(false);
  const history = useHistory();

  const refParent = useRef();
  const refPortal = useRef();
  const handleClick = (e) => {
    if (show && refPortal.current) {
      if (!refPortal.current.contains(e.target) && !refParent.current.contains(e.target)) {
        setShow(false);
      }
    }
  };
  useEffect(() => {
    setOnCloseCallback(() => { setShow(false); });
    document.addEventListener('mousedown', handleClick);

    return () => {
      setOnCloseCallback(null);
      document.removeEventListener('mousedown', handleClick);
    };
  });

  history.listen(() => {
    if (show) {
      setShow(false);
    }
  }, []);

  return (
    <Portal
      {...props}
      show={show}
      portalComponent={portalComponent}
      onClick={(e) => {
        if (!show) {
          onOpen();
        }
        if (show && refPortal.current && refPortal.current.contains(e.target)) {
          return;
        }
        setShow(!show);
      }}
      setPortalRef={(ref) => refPortal.current = ref.current}
      setParentRef={(ref) => refParent.current = ref.current}
    >
      {children}
    </Portal>
  );
};

ClicPortal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  portalComponent: PropTypes.node.isRequired,
  onOpen: PropTypes.func,
  setOnCloseCallback: PropTypes.func,
  // show: PropTypes.bool,
};

ClicPortal.defaultProps = {
  onOpen: () => {},
  setOnCloseCallback: () => {},
};

export default ClicPortal;
