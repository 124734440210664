import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { range } from 'lodash';
import ReactScrollWheelHandler from 'react-scroll-wheel-handler';
import Carousel from '../../../components/UI/Carousel';
import ButtonClose from '../../../components/UI/ButtonClose';
import stopAllVideosIframes from '../../../Lib/misc/stopAllVideosIframes';

const KoneSlideshowPage = ({
  children, type,
}) => (
  <div className={clsx('stdKoneSlideshow-page', type && `stdKoneSlideshow-page-${type}`)}>
    {children}
  </div>
);

KoneSlideshowPage.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

KoneSlideshowPage.defaultProps = {};

const KoneSlideshowVerticalScroll = ({
  children, onChangeSubPage,
}) => {
  const [curPage, setCurPage] = useState(0);

  const nbPages = children.length;

  useEffect(() => {
    stopAllVideosIframes('.stdKoneSlideshow-verticalScroll-container');
    onChangeSubPage && onChangeSubPage(curPage);
  }, [curPage]);

  return (
    <ReactScrollWheelHandler
      upHandler={(e) => curPage > 0 && setCurPage(curPage - 1)}
      downHandler={(e) => curPage < nbPages - 1 && setCurPage(curPage + 1)}
    ><div className={clsx('stdKoneSlideshow-verticalScroll')}>
      <div className="stdKoneSlideshow-verticalScroll-container" style={{ top: `${-curPage * 100}%` }}>{children}</div>
      <div className="stdKoneSlideshow-verticalScroll-indicators">{range(nbPages).map((index) => <button type="button" key={index} onClick={() => setCurPage(index)} className={clsx(curPage === index && 'act')}>&nbsp;</button>)}</div>

    </div>
    </ReactScrollWheelHandler>
  );
};

KoneSlideshowVerticalScroll.propTypes = {
  children: PropTypes.any.isRequired,
  onChangeSubPage: PropTypes.func,
};

KoneSlideshowVerticalScroll.defaultProps = {
  onChangeSubPage: null,
};

const KoneSlideshow = ({
  children, onClose,
}) => {
  const [curPage, setCurPage] = useState(0);
  const [curSubPage, setCurSubPage] = useState(null);

  const curComponent = Array.isArray(children) ? children[curPage] : children;
  const correctedChildren = React.Children.map(children, (child) => {
    if (child.type.name === KoneSlideshowVerticalScroll.name) {
      return React.cloneElement(curComponent, { onChangeSubPage: setCurSubPage });
    }
    return child;
  });

  const curSubComponent = curSubPage !== null && curComponent.type.name === KoneSlideshowVerticalScroll.name && curComponent.props.children[curSubPage];

  const pageVariant = curComponent.props && curComponent.props.variant;
  let addedClass = pageVariant ? pageVariant.split(' ').map((str) => `stdKoneSlideshow-variant-${str}`) : [];

  if (curSubComponent) {
    addedClass = [...addedClass, 'stdKoneSlideshow-variant-arrowsTop'];
    if (curSubComponent.props.variant) {
      addedClass = [...addedClass, ...curSubComponent.props.variant.split(' ').map((str) => `stdKoneSlideshow-variant-${str}`)];
    }
  }

  return (
    <div className={clsx('stdKoneSlideshow', addedClass)}>
      {onClose && <ButtonClose onClick={onClose} />}
      <Carousel
        count={1}
        withIndicators={false}
        dontComeBack
        onChangePage={(newPage) => {
          setCurPage(newPage);
          stopAllVideosIframes('.stdKoneSlideshow');
        }}
      >
        {correctedChildren}
      </Carousel>
    </div>

  );
};

KoneSlideshow.propTypes = {
  children: PropTypes.any.isRequired,
  onClose: PropTypes.func,
};

KoneSlideshow.defaultProps = {
  onClose: null,
};

export { KoneSlideshowPage, KoneSlideshowVerticalScroll };
export default KoneSlideshow;
