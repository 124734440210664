import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import exPhoto from '../../../img/FAFU/avatar_vide.png';

const Photo = ({
  src, small, className, ...props
}) => (
  <div
    className={clsx(
      'stdPhoto',
      small && 'stdPhoto-small',
      className,
    )}
    {...props}
  ><img src={src} />
  </div>
);

Photo.propTypes = {
  src: PropTypes.string.isRequired,
  small: PropTypes.bool,
  className: PropTypes.bool,
};
Photo.defaultProps = {
  small: false,
  className: null,
};

const PhotoForUtilisateur = ({
  utilisateur, ...props
}) => (
  <Photo src={utilisateur.avatar ? `${process.env.UPLOADS_URL}${utilisateur.avatar}` : exPhoto} {...props} />
);

PhotoForUtilisateur.propTypes = {
  utilisateur: PropTypes.shape({
    avatar: PropTypes.string,
  }).isRequired,
};

export { PhotoForUtilisateur };
export default Photo;
