import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Redirect, Route, Switch } from 'react-router';
import Chargement from '../components/UI/Chargement';
import Login from './Login/Login';

const UnloggedApp = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/chargement" component={() => <Chargement allHeight />} />
      <Route exact path="/connect-link/:lien" component={(props) => <Login {...props} />} />
      {/*<Route exact path="/pano" component={() => <Panorama />} />*/}
      <Route exact path="/:code" component={(props) => <Login {...props} />} />
      <Route exact path="/" component={() => <Login />} />
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>
);
export default UnloggedApp;
