import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Input from './Input';

const InputDate = ({
  onChange, value, ...props
}) => {
  const strFormat = 'mm/dd/YYYY';
  const format = 'LL/dd/yyyy';

  useEffect(() => {
    if (typeof value === 'object') {
      onChange(value.toFormat(format));
    }
  }, [value]);

  return (
    <Input
      {...props}
      placeholder={strFormat}
      value={typeof value === 'object' ? value.toFormat(format) : value}
      onChange={(newVal) => {
        let newValueFormatted = newVal;
        if (newValueFormatted.match(/^\d{2}$/) || newValueFormatted.match(/^\d{2}\/\d{2}$/)) {
          newValueFormatted += '/';
        }
        return onChange(newValueFormatted);
      }}
    />
  );
};

InputDate.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
};

InputDate.defaultProps = {
  value: '',
};
export default InputDate;
