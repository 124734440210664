let config;
let player;

// Array containing of streams
const streams = [
  [
    // English
    'XOzIC-Qo4zH', // high
    'XOzIC-RbJIu', // medium
    'XOzIC-kHeoI', // low
  ],
  [
    // Arabic
    'XOzIC-sd67y', // high
    'XOzIC-yGpNG', // medium
    'XOzIC-iQvYg', // low
  ],
  [
    // Chinese
    'XOzIC-MFLyR', // high
    'XOzIC-OcYMC', // medium
    'XOzIC-bXNnj', // low
  ],
  [
    // French
    'XOzIC-xlVEG', // high
    'XOzIC-OX7kX', // medium
    'XOzIC-VXW3Z', // low
  ],
];

const startPlayer = (playerDiv, stream_number) => {
  setActiveButton(stream_number);
  const stream = streams[stream_number];

  // Define the default stream to medium
  const startIndex = 1;

  config = {
    source: {
      entries: [
        {
          index: 0,
          h5live: {
            server: {
              websocket:
                'wss://bintu-h5live.nanocosmos.de:443/h5live/stream/stream.mp4',
              hls:
                'https://bintu-h5live.nanocosmos.de:443/h5live/http/playlist.m3u8',
              progressive:
                'https://bintu-h5live.nanocosmos.de:443/h5live/http/stream.mp4',
            },
            rtmp: {
              url: 'rtmp://bintu-play.nanocosmos.de:1935/play',
              streamname: stream[0],
            },
          },
        },
        {
          index: 1,
          h5live: {
            server: {
              websocket:
                'wss://bintu-h5live.nanocosmos.de:443/h5live/stream/stream.mp4',
              hls:
                'https://bintu-h5live.nanocosmos.de:443/h5live/http/playlist.m3u8',
              progressive:
                'https://bintu-h5live.nanocosmos.de:443/h5live/http/stream.mp4',
            },
            rtmp: {
              url: 'rtmp://bintu-play.nanocosmos.de:1935/play',
              streamname: stream[1],
            },
          },
        },
        {
          index: 2,
          h5live: {
            server: {
              websocket:
                'wss://bintu-h5live.nanocosmos.de:443/h5live/stream/stream.mp4',
              hls:
                'https://bintu-h5live.nanocosmos.de:443/h5live/http/playlist.m3u8',
              progressive:
                'https://bintu-h5live.nanocosmos.de:443/h5live/http/stream.mp4',
            },
            rtmp: {
              url: 'rtmp://bintu-play.nanocosmos.de:1935/play',
              streamname: stream[2],
            },
          },
        },
      ],
      options: {
        adaption: {
          rule: 'deviationOfMean2', // enable ABR (Automatic bitrate adaption)
          // none - Manual switching
          // deviationOfMean - Buffer based bitrate adaption rule
          // deviationOfMean2 - Enhanced buffer based bitrate adaption rule (added in v4.5)
        },
        switch: {},
      },
      startIndex,
    },
    playback: {
      autoplay: true,
      automute: true,
      muted: false,
      flashplayer: 'https://demo.nanocosmos.de/nanoplayer/nano.player.swf',
    },
    style: {
      controls: true, // Set to true to hide default control bar
    },
    events: {},
  };

  // Create a new instance
  player = new NanoPlayer(playerDiv);
  player.setup(config).then(
    (config) => {
      // console.log('setup success');
      // console.log(`config: ${JSON.stringify(config, undefined, 4)}`);
    },
    (error) => {
      // alert(error.message);
    },
  );
};

function setActiveButton(index) {
  document.getElementById('btnEN') && document.getElementById('btnEN').classList.remove('active');
  document.getElementById('btnAR') && document.getElementById('btnAR').classList.remove('active');
  document.getElementById('btnCH') && document.getElementById('btnCH').classList.remove('active');
  document.getElementById('btnFR') && document.getElementById('btnFR').classList.remove('active');

  switch (+index) {
    case 0:
      document.getElementById('btnEN') && document.getElementById('btnEN').classList.add('active');
      break;
    case 1:
      document.getElementById('btnAR') && document.getElementById('btnAR').classList.add('active');
      break;
    case 2:
      document.getElementById('btnCH') && document.getElementById('btnCH').classList.add('active');
      break;
    case 3:
      document.getElementById('btnFR') && document.getElementById('btnFR').classList.add('active');
      break;

    default:
      break;
  }
}

const switchLanguage = (index) => {
  player.destroy();
  startPlayer('playerDiv', index);
};

function play() {
  player.play();
}

function pause() {
  player.pause();
}

function fullscreen() {
  player
    .requestFullscreen()
    .then(() => {
      console.log('requestFullscreen resolved');
    })
    .catch((err) => {
      // error reasons can be 'denied' or 'disabled' (e.g. in audio player mode)
      console.log(`requestFullscreen rejected: ${err.reason}`);
    });
}

function mute() {
  player.mute();
}

function unmute() {
  player.unmute();
}
function stopPlayer() {
  player.destroy();
}

export default startPlayer;
export { switchLanguage, stopPlayer };
