import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../components/UI/Button';
import * as InterfaceAction from '../Redux/actions/Interface';

const Cookies = () => {
  const { cookies } = useSelector((state) => state.interfaceDataReducer);
  const dispatch = useDispatch();

  if (cookies) {
    return null;
  }

  return (
    <div className="stdCookies">
      <div>
        <div>
          We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you are happy with it.
        </div>
      </div>
      <Button small onClick={() => dispatch({ type: InterfaceAction.HIDE_COOKIES })}>Accept cookies</Button>
    </div>
  );
};

export default Cookies;
