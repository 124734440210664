import { useEffect } from 'react';
import * as Ably from 'ably';

const usePubsub = (topic, onNotify, dependencies = []) => {
  useEffect(() => {
    // console.log(`OPEN CHANNEL : ${topic}`, dependencies);
    const ably = new Ably.Realtime(process.env.ABLY_KEY);
    const fullTopic = `${process.env.SITENAME}/${topic}`;
    const channel = ably.channels.get(fullTopic);

    window.onbeforeunload = function () {
      // console.log('CLOSE CHANNEL');
      if (channel) {
        // console.log('CLOSE CHANNEL IS NOT NULL');
        channel.unsubscribe('message');
      }
      return undefined;
    };

    channel.subscribe('message', (message) => {
      const dataEvent = JSON.parse(message.data);
      if (dataEvent.action === 'reFetch') {
        onNotify();
        // window.setTimeout(() => {
        //   onNotify();
        // }, 3 * Math.random() * 1000);
      }
    });

    return () => {
      window.onbeforeunload = null;
      // console.log('CLOSE CHANNEL');
      channel.unsubscribe('message');
      // es.close();
    };
  }, dependencies);
};
export default usePubsub;
