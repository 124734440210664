import isScrollable from './isScrollable';


function getParentsNodes(element, _array) {
  if (!element) {
    return [];
  }
  if (_array === undefined) _array = [];
  else _array.push(element);
  if (element.tagName !== 'BODY') return getParentsNodes(element.parentNode, _array);
  return _array;
}


function getParentsNodesScrollables(element) {
  return getParentsNodes(element).filter((node) => window.getComputedStyle(node).overflowY === 'auto');
}

export default getParentsNodesScrollables;
