import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import withDroppable from '../HOC/withDroppable';
import iconeDownload from '../../../img/FAFU/iconeDownload.svg';

const FileDropzone = ({ onDroppedFiles, uploadingData }) => {
  const DropDropzoneDiv = withDroppable(({ children, ...props }) => <div className="stdDropzone" {...props}>{children}</div>);

  const { t } = useTranslation();
  const inputRef = useRef();
  return (
    <DropDropzoneDiv onClick={() => inputRef.current.click()} onDropFileCallback={onDroppedFiles}>
      <>
        <>
          <img src={iconeDownload} />Drop files to upload or <div className="stdDropzone-btn">BROWSE</div>
        </>
        <input
          ref={inputRef}
          type="file"
          className="stdHiddenUploadButton"
          onChange={(e) => onDroppedFiles([...e.target.files])}
          multiple
        />
      </>
    </DropDropzoneDiv>
  );
};
FileDropzone.propTypes = {
  onDroppedFiles: PropTypes.func.isRequired,
  uploadingData: PropTypes.array.isRequired,
};

FileDropzone.defaultProps = {
};

export default FileDropzone;
