import '../less/main.less';

import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import store from './Redux/store';
import App from './screens/App';
import i18n from './i18n';

require('../vtour/tour.js');

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <Suspense fallback="loading">
        <App />
      </Suspense>
    </I18nextProvider>
  </Provider>,
  document.getElementById('root'),
);
