import i18n from 'i18next';
import { fr, en } from './locales';



i18n.init({
  debug: false,
  returnEmptyString: false,
  lng: 'fr',
  fallbackLng: 'fr',
  defaultNS: 'translation',

  react: {
    wait: true,
  },
});

i18n.addResourceBundle('fr', 'translation', fr);
i18n.addResourceBundle('en', 'translation', en);

export default i18n;
