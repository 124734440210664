const Category = {
  GUEST: 'GUEST',
  VIP: 'VIP',
  CONCIERGE: 'CONCIERGE',
  MEDIA: 'MEDIA',
  SPEAKER: 'SPEAKER',
  STAFF: 'STAFF',
};
const arCategories = [
  Category.GUEST,
  Category.VIP,
  Category.CONCIERGE,
  Category.MEDIA,
  Category.SPEAKER,
  Category.STAFF,
];
export { arCategories };
export default Category;
