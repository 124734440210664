
const doHandleRestError = (errorMessage) => {
  if (errorMessage.search('not a valid image') !== -1) {
    return "Votre fichier n'est pas une image valide";
  }
  if (errorMessage.search('is too small') !== -1) {
    const found = errorMessage.match(/is (\d+)px/);
    if (found.length > 1) {
      return `Votre image doit être de largeur supérieure à ${found[1]}px`;
    }
    return 'Votre image doit être de largeur supérieure ';
  }
  if (errorMessage.search('violated a constraint') !== -1) {
    const found = errorMessage.match(/violated a constraint "(.+)"$/);
    if (found.length > 1) {
      return found[1];
    }
    return 'Le formulaire semble incorrecte ';
  }
  return errorMessage;
};


export const handleRestError = (error) => {
  if (error && error.response && error.response.data && error.response.data.message) {
    return doHandleRestError(error.response.data.message);
  }
  return error.message;
};
