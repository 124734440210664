import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Button = ({
  children, small, wide, variant, className, icon, iconAfter, ...props
}) => (
  <button
    type="button"
    className={clsx(
      'stdBtn',
      small && 'stdBtn-small',
      wide && 'stdBtn-wide',
      variant && variant.split(' ').map((el) => `stdBtn-${el}`),
      className,
    )}
    {...props}
  >
    { icon && <i className={`stdBtn-icon ptl-${icon}`} />}
    {children}
    { iconAfter && <i className={`stdBtn-iconAfter ptl-${iconAfter}`} />}
  </button>
);

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  variant: PropTypes.string,
  icon: PropTypes.string,
  iconAfter: PropTypes.string,
  className: PropTypes.string,
  small: PropTypes.bool,
  wide: PropTypes.bool,
};

Button.defaultProps = {
  small: false,
  wide: false,
  variant: null,
  icon: null,
  iconAfter: null,
  className: '',
  children: null,
};

export default Button;
