import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import SelectPannel from './SelectPannel';
import Portal from '../../Portal/Portal';

const Select = ({
  placeholder, onChange, multiple, value, required, options, hasError, isTouched, onPopup, withFilter, ...props
}) => {
  const inputFilterRef = useRef();
  const [filter, setFilter] = useState(null);
  const [opened, setOpened] = useState(false);
  const [currentLabel, setCurrentLabel] = useState(null);
  const pannelRef = useRef(null);
  const btnRef = useRef(null);

  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  });
  const getLabel = () => currentLabel || placeholder;

  useEffect(() => {
    if (opened && inputFilterRef.current) {
      inputFilterRef.current.focus();
    }
  }, [opened]);

  useEffect(() => {
    if (filter && filter.length > 1) {
      window.dispatchEvent(new Event('resize'));
    }
  }, [filter]);

  const onFocus = () => {
    if (!opened) {
      setOpened(true);
    }
  };

  const handleKeyUp = (e) => { // scroll to key
    if (opened && e.keyCode > 64 && e.keyCode < 91) {
      const letter = String.fromCharCode(e.keyCode).toLowerCase();

      let firstOptionIndex = null;
      let i = 0;
      while (!firstOptionIndex && i < options.length) {
        if (options[i].label.charAt(0).toLowerCase() === letter) {
          firstOptionIndex = i;
        }
        i += 1;
      }
      if (firstOptionIndex && pannelRef.current) {
        const elementChoosed = pannelRef.current.childNodes[firstOptionIndex];
        const topPos = elementChoosed.offsetTop;

        pannelRef.current.scrollTop = topPos;
      }
    }
  };

  const onBlur = (e, bForce) => {
    if (e.relatedTarget && pannelRef.current && pannelRef.current.contains(e.relatedTarget)) {
      e.target.focus();
      return false;
    }
    if (e.relatedTarget && inputFilterRef.current && inputFilterRef.current.contains(e.relatedTarget)) {
      // e.target.focus();
      return false;
    }
    setFilter(null);
    setOpened(false);
  };

  const getFilteredOptions = () => {
    if (!withFilter || !filter || filter.length < 2) {
      return options;
    }
    return options.filter(({ label }) => label.toLowerCase().search(filter.toLowerCase()) > -1);
  };

  const onChooseOption = (choosedOptionValue) => {
    if (multiple) {
      if (value.includes(choosedOptionValue)) {
        onChange(value.filter((testVal) => testVal !== choosedOptionValue));
      } else {
        onChange([...value, choosedOptionValue]);
      }
    } else if (value !== choosedOptionValue) {
      onChange(choosedOptionValue);
    } else if (!required) {
      onChange(null);
    }

    if (!multiple && btnRef.current) {
      btnRef.current.blur();
      setOpened(false);
    }
  };

  useEffect(() => {
    let selectedLabels = [];
    if (Array.isArray(value)) {
      selectedLabels = options.filter((option) => value.includes(option.value)).map(({ label }) => label);
    } else {
      selectedLabels = options.filter((option) => option.value === value).map(({ label }) => label);
    }

    if (!multiple && selectedLabels.length && typeof selectedLabels[0] === 'object') {
      setCurrentLabel(selectedLabels[0]);
    } else {
      setCurrentLabel(selectedLabels.length ? selectedLabels.join(', ') : null);
    }
  }, [value]);

  return (
    <Portal
      takeWidthFromParent
      show={opened}
      onPopup={onPopup}
      portalComponent={(
        <SelectPannel
          pannelRef={pannelRef}
          options={getFilteredOptions()}
          value={value}
          onChooseOption={onChooseOption}
          {...props}
        />
      )}
    >
      <button
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onFocus}
        ref={btnRef}
        type="button"
        className={clsx(
          'stdSelect-label',
          opened && 'stdSelect-label-opened',
          !value && 'stdSelect-label-isPlaceholder',
          hasError && isTouched && 'stdSelect-label-error',
          !hasError && isTouched && 'stdSelect-label-correct',
        )}
      >
        {(opened && withFilter) ? <input ref={inputFilterRef} type="text" value={filter} onChange={(e) => setFilter(e.target.value)} placeholder={placeholder} /> : <span>{getLabel()}</span>}
        <i className="ptl-carret" />
      </button>

    </Portal>

  );
};

Select.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  hasError: PropTypes.bool,
  withFilter: PropTypes.bool,
  isTouched: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onPopup: PropTypes.bool,

};

Select.defaultProps = {
  placeholder: 'Choisir',
  multiple: false,
  required: false,
  withFilter: false,
  hasError: false,
  isTouched: false,
  value: [],
  onPopup: false,
};
export default Select;
