import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { range } from 'lodash';

const InputRate = ({
  onChange, value, ...props
}) => {
  const [hoverValue, setHoverValue] = useState(null);

  return (
    <div className="stdRate">
      {range(5).map((index) => (
        <button
          onMouseOver={() => setHoverValue(index + 1)}
          onMouseOut={() => setHoverValue(null)}
          key={index}
          type="button"
          className={clsx(
            value > index && 'act',
            hoverValue > index && 'hoverAct',
          )}
          onClick={() => onChange(index + 1)}
        >&nbsp;
        </button>
      ))}
    </div>
  );
};

InputRate.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
};

InputRate.defaultProps = {
  value: '',
};
export default InputRate;
