import React from 'react';
import PropTypes from 'prop-types';

const Menu = ({ items }) => (
  <ul className="stdMenu">
    {(Array.isArray(items) ? items : [items]).map((el, index) => <li key={index}>{el}</li>)}
  </ul>
);

Menu.propTypes = {
  items: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

Menu.defaultProps = {
  // data: [],
};
export default Menu;
