import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Redirect, Route, Switch, useHistory, useLocation,
} from 'react-router';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import PublicChat from './PublicChat';
import useRestClient from '../../Lib/useRestClient';
import NavButton from '../../components/UI/NavButton';
import usePubsub from '../../Lib/usePubsub';
import ondes from '../../../img/T20/ondes.svg';
import Settings from './Settings';
import StaffEvenementDiv from './StaffEvenementDiv';
import Button from "../../components/UI/Button";
import axios from "axios";

const StaffSpaceMenu = () => (
  <div className="stdStaffSpace-menu">
    <NavLink style={{ backgroundImage: `url(${ondes})` }} to="/settings">SETTINGS</NavLink>
  </div>
);

const StaffSpaceBackButton = () => {
  const location = useLocation();
  const history = useHistory();

  return (
    <button
      className={clsx('stdStaffSpace-backMenu', location.pathname !== '/' && 'act')}
      type="button"
      onClick={() => {
        history.push('/');
      }}
    ><i className="ptl-play" /> Back to home
    </button>
  );
};

const StaffSpaceEmergency = () => {
  const [lastAction, setLastAction] = useState(DateTime.local());
  const [notifications] = useRestClient('/staff/notifications', null, [lastAction]);
  const dummyEnd = useRef();
  const nbMessages = 10;

  usePubsub('programme/notifications', () => {
    setLastAction(DateTime.local());
  });

  useEffect(() => {
    if (dummyEnd.current) {
      dummyEnd.current.parentNode.scrollTop = dummyEnd.current.offsetTop;
    }
  }, [notifications]);

  if (!notifications) {
    return <div className="stdStaffSpace-emergencyDashboard-contenu" />;
  }
  return (
    <div className="stdStaffSpace-emergencyDashboard stdTab">
      <div className="stdTab-titre">
        <span>Emergency Dashboard</span>
      </div>

      <div className="stdTab-content">
        <div className="stdStaffSpace-emergencyDashboard-contenu">
          {notifications.slice(notifications.length - nbMessages).map(({
            id, message, author, category, created,
          }) => (
            <div key={id} className={clsx('stdStaffSpace-emergencyDashboard-message')}>
              <div>
                <div>{`${author.firstname} ${author.lastname}`}</div>
                {created && <div>{created.toFormat('HH:mm')}</div>}
              </div>
              <div>{message}</div>
            </div>
          ))}
          <div style={{ float: 'left', clear: 'both' }} ref={dummyEnd} />
        </div>
        <NavButton variant="danger" small to="/green-room">Go to emergency dashboard</NavButton>
      </div>
    </div>
  );
};

const StaffSpace = () => {
  const location = useLocation();


  const logout = async () => {
    await axios.get('logout');
    window.location.href = '/chargement';
  };

  return (
    <div className="stdStaffSpace">
      <div className="stdContainer">

        <div className="stdStaffSpace-top">
          <StaffSpaceBackButton />
          <Button onClick={logout}>Logout</Button>
          <StaffEvenementDiv />
        </div>

        <div className={clsx('stdStaffSpace-content', location.pathname === '/green-room' && 'stdStaffSpace-chatMaxed')}>
          <Switch>

            <Route exact path="/settings" render={() => <Settings />} />
            <Route exact path="/" render={() => <StaffSpaceMenu />} />
            <Redirect from="*" to="/" />
          </Switch>
          <PublicChat className="stdStaffSpace-chatTab" />

          {/* {location.pathname !== '/green-room' && <StaffSpaceEmergency />} */}

        </div>
      </div>
    </div>
  );
};
export default StaffSpace;
