import { useEffect, useState } from 'react';
import axios from 'axios';

const useRestClient = (url, initialData = null, dependencies = null) => {
  let _isMounted = false;

  const [data, setData] = useState(initialData);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const fetchData = async () => {
    setIsError(false);
    try {
      const result = await axios(url);
      if (_isMounted) {
        setData(result.data);
        setIsLoading(false);
      }
    } catch (error) {
      if (_isMounted) {
        setIsError(error);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    _isMounted = true;
    fetchData();
    return () => {
      _isMounted = false;
    };
  }, dependencies ? [url, ...dependencies] : [url]);

  return [data, isLoading, isError, fetchData, setData];
};

export default useRestClient;
