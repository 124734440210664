import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import logo from '../../img/KO/logo.svg';
import Fieldset from '../components/UI/Form/Fieldset';
import InputRate from '../components/UI/Form/InputRate';
import Button from '../components/UI/Button';
import * as InterfaceAction from '../Redux/actions/Interface';
import Chargement from '../components/UI/Chargement';

const RegisterEmail = () => {
  const recaptchaRef = useRef();
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  return (
    <div className="component-registerEmail">
      <img src={logo} alt="Kone" />
      <div className="component-registerEmail-content">
        <Formik
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const captchaVal = await recaptchaRef.current.executeAsync();
              setError(null);
              try {
                await axios.post('kone-subscribe', {
                  ...values,
                  captcha: captchaVal,
                  hasLoguedAfter: true,
                });
              } catch (err) {
                if (setSubmitting) {
                  setSubmitting(false);
                }

                if (recaptchaRef.current) {
                  recaptchaRef.current.reset();
                }
              }
              dispatch({ type: InterfaceAction.SET_EMAIL, email: values.email });

              setSubmitting(false);
            } catch (err) {
              setSubmitting(false);
            // console.log('captcha err', err);
            }
          }}
          initialValues={{
            email: '',
          }}

          validationSchema={Yup.object().shape({
            email: Yup.string().required('Please provide an email').typeError('Please provide an email'),
          })}
        >

          {({
            values, setFieldValue, handleSubmit, isSubmitting,
          }) => (
            <Form className="stdForm form-100 form-center center">
              <div className="midMargin-y">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey="6Ldcq9EZAAAAAI7TW8UyizDAMUnD1L230q4S5OBz"
                />
                <Fieldset name="email" type="email" placeholder="EMAIL">Enter your Email address to access the event</Fieldset>
              </div>
              <br />
              <Button type="submit" className="stdBtn">Submit</Button>
              {error && <div className="error smallMargin-t center">{error}</div>}
              {isSubmitting && <><br /><br /><Chargement withoutText /></>}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default RegisterEmail;
