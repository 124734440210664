import React, { useState } from 'react';
import DateTime from 'luxon/src/datetime';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import CheckboxWithLabel from '../../components/UI/CheckboxWithLabel';
import useRestClient from '../../Lib/useRestClient';
import Button from '../../components/UI/Button';
import useInterface from '../../Lib/useInterface';
import * as StateAction from '../../Redux/actions/State';
import ButtonClose from '../../components/UI/ButtonClose';
import usePubsub from '../../Lib/usePubsub';

const LiveSondage = () => {
  const [lastAction, setLastAction] = useState(DateTime.local());
  const { sendRestErrorMessage, sendMessage } = useInterface();
  const [curAnswerIndex, setCurAnswerIndex] = useState(null);
  const dispatch = useDispatch();

  const idSondagesRepondus = useSelector((state) => state.stateDataReducer.idSondagesRepondus);
  const [currentSondage] = useRestClient('current-sondage', null, [lastAction]);

  usePubsub('polls', () => {
    setCurAnswerIndex(null);
    setLastAction(DateTime.local());
  });

  if (!currentSondage) {
    return null;
  }

  if (idSondagesRepondus && idSondagesRepondus.includes(currentSondage.id)) {
    return null;
  }

  const doVote = async (answerIndex) => {
    try {
      const resp = await axios.post(`/sondages/${currentSondage.id}/vote`, {
        answerIndex,
      });
      setLastAction(DateTime.local());
      setCurAnswerIndex(null);

      if (answerIndex !== null) {
        sendMessage('Your vote had been received');
      }

      dispatch({ type: StateAction.SET_SONDAGES_REPONDUS, idSondagesRepondus: resp.data });
    } catch (err) {
      sendRestErrorMessage(err);
    }
  };

  return (
    <div className="component-liveSondage">
      <div className="stdTab">
        <div className="stdTab-titre"><span>Live poll</span></div>
        <div className="stdTab-content">
          <ButtonClose
            className="floatRight"
            onClick={() => {
              doVote(null);
            }}
          />
          <h3>{currentSondage.question}</h3>

          <ul className="midMargin-t">
            {currentSondage.reponses.map((reponse, index) => (
              <li className="midMargin-y" key={index}>
                <CheckboxWithLabel
                  isAct={index === curAnswerIndex}
                  onClick={() => {
                    if (index === curAnswerIndex) {
                      setCurAnswerIndex(null);
                    } else {
                      setCurAnswerIndex(index);
                    }
                  }}
                > <span className="bold">{String.fromCharCode(97 + index).toUpperCase()}.</span> {reponse}
                </CheckboxWithLabel>
              </li>
            ))}
          </ul>

          <Button wide className="bigMargin-t" onClick={() => doVote(curAnswerIndex)}>Vote</Button>

        </div>
      </div>
    </div>
  );
};

export default LiveSondage;
