import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import SA from 'svg-country-flags/svg/sa.svg';
import EN from 'svg-country-flags/svg/gb.svg';

const LANG_EN = 'EN';
const LANG_SA = 'SA';

const MenuLang = ({ val, setLang, textVersion }) => (
  <div className="stdMenuLang">
    <button type="button" className={clsx(LANG_SA === val && 'act')} onClick={() => setLang(LANG_SA)}>
      { textVersion ? <p>AR</p> : <img src={SA} /> }
    </button>
    <button type="button" className={clsx(LANG_EN === val && 'act')} onClick={() => setLang(LANG_EN)}>
      { textVersion ? <p>EN</p> : <img src={EN} />}
    </button>
  </div>
);

MenuLang.propTypes = {
  textVersion: PropTypes.bool.isRequired,
  val: PropTypes.string.isRequired,
  setLang: PropTypes.func.isRequired,
};
MenuLang.defaultTypes = {
  textVersion: true,
};

export { LANG_SA, LANG_EN };
export default MenuLang;
