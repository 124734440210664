import React from 'react';
import PropTypes from 'prop-types';

const StaffTab = ({ titre, rightContent, children }) => (
  <div className="stdStaffTab">
    <div className="stdStaffTab-titre">
      <h1>{titre}</h1>
      { rightContent && <div className="stdStaffTab-droite">{rightContent}</div> }
    </div>
    <div className="stdStaffTab-content">{children}</div>
  </div>
);

StaffTab.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  rightContent: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  titre: PropTypes.string.isRequired,
};
StaffTab.defaultProps = {
  rightContent: null,
};

export default StaffTab;
