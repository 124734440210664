import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Input from './Input';

const InputWithIcon = ({
  icon, onIconClic, variant, ...props
}) => (
  <div className={clsx('stdInputContainer', `stdInputContainer-${variant}`)}>
    <Input variant={variant} {...props} />
    <i className={`ptl-${icon}`} onClick={onIconClic} style={{ cursor: onIconClic && 'pointer' }} />
  </div>
);

InputWithIcon.propTypes = {
  icon: PropTypes.string,
  onIconClic: PropTypes.func,
  variant: PropTypes.string,
};

InputWithIcon.defaultProps = {
  icon: null,
  onIconClic: () => {},
  variant: null,
};

export default InputWithIcon;
