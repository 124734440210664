import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import axios from 'axios';
import useInterface from '../Lib/useInterface';
import Button from '../components/UI/Button';

const SwitchUserPannel = () => {
  const [isHovered, setIsHovered] = useState(false);
  const fakedUser = useSelector((state) => state.stateDataReducer.loggedUtilisateur);
  const realUser = useSelector((state) => state.stateDataReducer.realUser);
  const { sendRestErrorMessage, sendMessage } = useInterface();

  if (!realUser) {
    return null;
  }

  const quitterSwitchUtilisateur = async () => {
    try {
      const resp = await axios.get('/staff/switchUser/quitter');
      sendMessage('You have been disconnected of this user');
      window.location.href = '/users';
    } catch (err) {
      sendRestErrorMessage(err);
    }
  };

  return (
    <div className={clsx('stdSwitchUser', isHovered && 'act')} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <div>You are connected <br /> as {fakedUser.firstname} {fakedUser.lastname}</div>
      <Button onClick={quitterSwitchUtilisateur} small variant="white" classes="smallMargin-t">Back to admin</Button>
    </div>
  );
};

SwitchUserPannel.propTypes = {};

SwitchUserPannel.defaultProps = {
  // data: [],
};

export default SwitchUserPannel;
