import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Checkbox = ({
  isAct, divVersion, multiple, ...props
}) => (divVersion ? (
  <div
    {...props}
    className={clsx(
      'stdCheckbox',
      isAct && 'stdCheckbox-act',
      multiple && 'stdCheckbox-multiple',
    )}
  />
) : (
  <button
    {...props}
    type="button"
    className={clsx(
      'stdCheckbox',
      isAct && 'stdCheckbox-act',
      multiple && 'stdCheckbox-multiple',
    )}
  />
));

Checkbox.propTypes = {
  isAct: PropTypes.bool,
  multiple: PropTypes.bool,
  divVersion: PropTypes.bool,
};

Checkbox.defaultProps = {
  isAct: false,
  multiple: false,
  divVersion: false,
};

export default Checkbox;
