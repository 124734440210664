import { useDispatch } from 'react-redux';
import { handleRestError } from './misc/handleRestError';
import * as InterfaceAction from '../Redux/actions/Interface';

const useInterface = () => {
  const dispatch = useDispatch();
  const changePage = (page) => {
    dispatch({ type: InterfaceAction.SET_PAGE, page });
  };
  const setCurrentSceneInPanorama = (scene) => {
    dispatch({ type: InterfaceAction.SET_CURRENT_SCENE_IN_PANORAMA, scene });
  };
  const sendMessage = (titre, contenu, variant = null) => {
    window.flash({ titre, contenu, variant });
  };
  const sendErrorMessage = (contenu, titre = 'Error') => {
    window.flash({ titre, contenu, error: true });
  };

  const sendRestErrorMessage = (error) => {
    window.flash({ titre: 'Error', contenu: handleRestError(error), error: true });
  };
  const moveToPanoScene = (scene) => {
    window.krpanoCommand(scene);
  };
  const setShowTrailer = (bShowTrailer) => {
    dispatch({ type: InterfaceAction.SET_SHOW_TRAILER, bShowTrailer });
  };

  const switchTesting = () => {
    dispatch({ type: InterfaceAction.SWITCH_TESTING });
  };

  return {
    sendMessage, sendErrorMessage, sendRestErrorMessage, changePage, setCurrentSceneInPanorama, switchTesting, moveToPanoScene, setShowTrailer
  };
};

export default useInterface;
