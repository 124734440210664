const stopAllVideosIframes = (baseElementClass) => {
  const baseElement = document.querySelector(baseElementClass);
  const iframes = baseElement.querySelectorAll('iframe');
  iframes.forEach((iframe) => {
    const iframeSrc = iframe.src;
    iframe.src = iframeSrc;
  });
};

export default stopAllVideosIframes;
