import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

function useBackButton(handler) {
  const history = useHistory();
  useEffect(() => {
    window.addEventListener('popstate', () => {
      window.history.pushState(null, document.title, window.location.href);
      handler();
    });

    return () => {
      window.removeEventListener(
        'popstate',
        handler,
      );
    };
  }, [handler]);
}

export default useBackButton;
