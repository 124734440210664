import React from 'react';
import PropTypes from 'prop-types';

const ErrorBoundaryContenu = ({ error }) => (
  <div className="component-error">
    <h2>An error has occured</h2>
    <p>Our teams had been noticed of this problem</p>
  </div>
);

ErrorBoundaryContenu.propTypes = {
  error: PropTypes.object.isRequired,
};

ErrorBoundaryContenu.defaultProps = {};

export default ErrorBoundaryContenu;
