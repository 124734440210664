import React from 'react';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';

const LoginCompteur = ({ date }) => (
  <Countdown
    date={date.toJSDate()}
    intervalDelay={0}
    precision={3}
    renderer={({ days, hours, minutes }) => (
      <div className="stdCompteur">
        <div>
          <div>
            <div>{days < 10 ? `0${days}` : days}</div>
            <div>days</div>
          </div>
        </div>
        <div>
          <div>
            <div>{hours < 10 ? `0${hours}` : hours}</div>
            <div>hours</div>
          </div>
        </div>
        <div>
          <div>
            <div>{minutes < 10 ? `0${minutes}` : minutes}</div>
            <div>minutes</div>
          </div>
        </div>
      </div>
    )}
  />

);

LoginCompteur.propTypes = {
  date: PropTypes.object.isRequired,
};

LoginCompteur.defaultProps = {};
export default LoginCompteur;
