import React from 'react';
import { useSelector } from 'react-redux';
import TopWindow from './TopWindow';

const MainSpace = () => {
  const currentPage = useSelector((state) => state.interfaceDataReducer.currentPage);

  return (
    <div className="stdMainSpace">
      <TopWindow />

    </div>
  );
};

export default MainSpace;
