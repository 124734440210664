import React from 'react';
import PropTypes from 'prop-types';
import countries from 'i18n-iso-countries';
import { PhotoForUtilisateur } from './Photo';
import salutation from "../../Lib/misc/salutation";

const SpeakerDesc = ({
  utilisateur, small, ...props
}) => (
  <>
    <PhotoForUtilisateur utilisateur={utilisateur} small={small} />
    <div {...props}>
      <h4><div>{`${salutation(utilisateur.salutation)} ${utilisateur.firstname}`}</div> <div>{`${utilisateur.lastname}`}</div></h4>
      <ul>
        <li>{ utilisateur.position }</li>
        <li>{ utilisateur.company }</li>
        <li>{ countries.getName(utilisateur.country, 'en') }</li>
      </ul>
    </div>
  </>
);

SpeakerDesc.propTypes = {
  utilisateur: PropTypes.shape({
    salutation: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    position: PropTypes.string,
    company: PropTypes.string,
    country: PropTypes.string,
  }).isRequired,
  small: PropTypes.bool,
};

SpeakerDesc.defaultProps = {
  small: false,
};

export default SpeakerDesc;
