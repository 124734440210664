import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import Popup from '../../components/UI/Popup/Popup';
import useInterface from '../../Lib/useInterface';
import Fieldset from '../../components/UI/Form/Fieldset';
import Button from '../../components/UI/Button';
import InputDate from '../../components/UI/Form/InputDate';

const EditEvenementDatePopup = ({ evenement, onCancel, onConfirm }) => {
  const { sendRestErrorMessage, sendMessage } = useInterface();

  return (
    <Popup onClosePopup={onCancel}>
      <h1 className="mid bold midMargin-b">Event date/length</h1>
      <Formik
        initialValues={evenement}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const result = await axios.patch(`/staff/evenement/${evenement.id}`, values);
            sendMessage('The date has been saved');
            onConfirm(result.data);
          } catch (err) {
            sendRestErrorMessage(err);
          }
          setSubmitting(false);
        }}

        validationSchema={Yup.object().shape({
          date: Yup.string().required('You must provide this field').typeError('You must provide this field').matches(/^\d{2}\/\d{2}\/\d{4}$/, 'The format should be mm/dd/yyyy'),
          duree: Yup.number().required('You must provide this field'),
        })}
      >
        {({ values, isSubmitting, submit }) => (
          <Form className="stdForm stdForm-onWhite form-100">

            <Fieldset name="date" input={(otherProps) => <InputDate {...otherProps} />}>Date</Fieldset>
            <Fieldset name="duree" type="number" min="1" max="4">Event duration (days)</Fieldset>

            <Button variant="transparent" onClick={onCancel}>Cancel</Button>
            <Button type="submit">OK</Button>
          </Form>
        )}
      </Formik>
    </Popup>
  );
};

EditEvenementDatePopup.propTypes = {
  evenement: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EditEvenementDatePopup;
