import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import parsePhoneNumber from 'libphonenumber-js';
import useRestClient from '../../Lib/useRestClient';
import EvenementState from '../../Lib/EvenementState';
import logo from '../../../img/KO/logo.svg';
import fb from '../../../img/KO/Social/fb.svg';
import insta from '../../../img/KO/Social/insta.svg';
import twitter from '../../../img/KO/Social/twitter.svg';
import linkedin from '../../../img/KO/Social/in.svg';
import back from '../../../img/KO/back.svg';
import backTour from '../../../img/KO/backTour.svg';
import handler from '../../../img/KO/handler.svg';
import photo1 from '../../../img/KO/Login/photo1.jpg';
import photo2 from '../../../img/KO/Login/photo2.jpg';
import recognLogo1 from '../../../img/KO/Login/logo1.png';
import recognLogo2 from '../../../img/KO/Login/logo2.png';
import recognLogo3 from '../../../img/KO/Login/logo3.png';
import recognLogo4 from '../../../img/KO/Login/logo4.png';
import recognLogo5 from '../../../img/KO/Login/logo5.png';
import recognLogo6 from '../../../img/KO/Login/logo6.png';
import Fieldset from '../../components/UI/Form/Fieldset';
import Button from '../../components/UI/Button';
import Chargement from '../../components/UI/Chargement';
import LoginCompteur from './LoginCompteur';
import useScroll from '../../Lib/useScroll';
import koneICS from '../../../file/KO/KONEDX.ics';
import Video from '../../components/UI/Video';
import FieldsetSelectCountry from '../../components/UI/Form/FieldsetSelectCountry';

const LoginHeader = ({ }) => {
  const [respMenuOpened, setRespMenuOpened] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [currentSection, setCurrentSection] = useState(0);

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.scrollTop);
    };
    document.getElementById('root').addEventListener('scroll', onScroll);

    const offset = -400;
    const maxAtteint = ['section-1', 'section-2', 'section-3', 'section-4', 'section-5'].reduce((max, sectionId) => {
      const topPos = document.getElementById(sectionId).getBoundingClientRect().top + scrollTop;
      if (scrollTop > topPos + offset) {
        if (!document.getElementById(sectionId).classList.contains('act')) {
          document.getElementById(sectionId).classList.add('act');
        }
        return max + 1;
      }
      return max;
    }, 0);

    if (maxAtteint !== currentSection) {
      setCurrentSection(maxAtteint - 1);
    }

    return () => document.getElementById('root').removeEventListener('scroll', onScroll);
  }, [scrollTop]);

  const gotoSection = (nbSection) => {
    if (respMenuOpened) {
      setRespMenuOpened(false);
    }
    const targetElement = document.getElementById(`section-${nbSection + 1}`);
    const offset = 200;
    document.getElementById('root').scrollTop = targetElement.getBoundingClientRect().top + document.getElementById('root').scrollTop - offset;
  };

  return (
    <section className={clsx('component-connexion-header', scrollTop > 10 && 'component-connexion-header-scrollPassed', respMenuOpened && 'act')}>
      <div className="stdContainer">

        <button type="button" onClick={() => setRespMenuOpened(!respMenuOpened)}> <img src={handler} alt="Kone" /></button>
        <img src={logo} alt="Kone" />

        <nav>
          <button type="button" className={clsx('component-connexion-header-btn', currentSection === 0 && 'act')} onClick={() => gotoSection(0)}>Register</button>
          <button type="button" className={clsx('component-connexion-header-btn', currentSection === 1 && 'act')} onClick={() => gotoSection(1)}>Speakers</button>
          <button type="button" className={clsx('component-connexion-header-btn', currentSection === 2 && 'act')} onClick={() => gotoSection(2)}>Ready to connect</button>
          <button type="button" className={clsx('component-connexion-header-btn', currentSection === 3 && 'act')} onClick={() => gotoSection(3)}>Mission and vision</button>
          <button type="button" className={clsx('component-connexion-header-btn', currentSection === 4 && 'act')} onClick={() => gotoSection(4)}>Recognitions</button>
        </nav>

        <div>
          <a href="https://www.kone.ae/en/about-us/contact-us/?utm_source=DXEVENT&utm_medium=lp&utm_campaign=KMEA-DX-EVENT2021" target="_blank" rel="noreferrer" className="component-connexion-header-btn">Contact us</a>
          <div>
            <a href="https://www.facebook.com/KONEMiddleEast" target="_blank" rel="noreferrer"><img src={fb} alt="fb" /></a>
            <a href="https://www.instagram.com/kone.mea/" target="_blank" rel="noreferrer"><img src={insta} alt="insta" /></a>
            <a href="https://twitter.com/KONE_MEAfrica" target="_blank" rel="noreferrer"><img src={twitter} alt="twitter" /></a>
            <a href="https://www.linkedin.com/company/5047" target="_blank" rel="noreferrer"><img src={linkedin} alt="in" /></a>
          </div>

        </div>
      </div>
    </section>
  );
};
const Login = ({ match }) => {
  const recaptchaRef = useRef();
  const [error, setError] = useState(null);
  const [hasSent, setHasSent] = useState(false);
  const { t } = useTranslation();
  const curScroll = useScroll();

  const [config] = useRestClient('/config');
  // const shouldDisplayLogin = (config && parseInt(config.isFinished) !== EvenementState.UNSTARTED) || (match && match.params && match.params.code === 'testKO!!!');
  // const shouldDisplayLogin = false || (match && match.params && match.params.code === 'testFII!!!');

  const onChangeCapcha = (val) => {
  };

  const addToCalendar = () => {
    const data = [
      'BEGIN:VCALENDAR',
      'VERSION:2.0',
      'BEGIN:VEVENT',
      'DTSTART:20210630T075000Z',
      'DTEND:20210630T190000Z',
      'SUMMARY:Summary',
      'DESCRIPTION:DESCRIPTION',
      'LOCATION:location',
      'END:VEVENT',
      'END:VCALENDAR',
    ].join('\n');

    const filename = 'export.ics';
    const blob = new Blob([data], { type: 'text/calendar;charset=utf-8' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // shouldDisplayLogin = false;

  return (
    <div className="component-connexion">

      <div className="component-connexion-backTour" style={{ transform: `translate3d(0, ${800 - curScroll / 5}px, 0)` }}><img src={backTour} alt="backTour" /></div>
      <LoginHeader />

      <section className="component-connexion-top">
        <div className="stdContainer stdContainer-withPadding">
          <LoginCompteur date={DateTime.fromFormat('30/06/2021 11:50', 'dd/LL/yyyy HH:mm', {
            zone: 'UTC+4',
          })}
          />

          <div>
            <h1>KONE DX <br />
              VIRTUAL DEEP <br />
              DIVE EVENT
            </h1>
            <h2>Connect to the
              Flow of Tomorrow
            </h2>
            <p>Welcome to CONNECT TO THE FLOW OF TOMORROW – the KONE DX event. We are excited to invite you to explore a new era in connected people flow with the KONE DX – the world’s first digitally connected elevator.</p>
          </div>
          <div className="component-connexion-back" style={{ transform: `translate3d(0, ${curScroll / 5}px, 0)` }}><img src={back} alt="back" /></div>
        </div>
      </section>

      <hr className="stdHr stdHr-green" />

      <section className="component-connexion-section2Form" id="section-1">
        <div className="stdContainer stdContainer-50 stdContainer-withPadding">

          { hasSent ? (
            <div className="component-connexion-section2Form-hasSent">
              <h2>THANK YOU <br />FOR REGISTERING</h2>
              <p><a href={koneICS} className="stdBtn">ADD TO YOUR CALENDAR</a></p>

              <p>You will receive an email reminder and link one day prior to the event.</p>

              <p>We are looking forward to welcoming you at
                <div className="mainLight">12:00pm Dubai time (GMT+4), <br />
                  the 30th of June for our CONNECT TO THE FLOW OF TOMORROW event.
                </div>
              </p>

              <p className="main uppercase">KONE DX – CONNECTING MORE THAN FLOORS</p>
            </div>
          ) : (
            <Formik
              initialValues={{
                name: '',
                country: '',
                company: '',
                phone: '',
                email: '',
                hasLoguedAfter: false,
              }}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  const captchaVal = await recaptchaRef.current.executeAsync();
                  setError(null);
                  try {
                    await axios.post('kone-subscribe', {
                      ...values,
                      captcha: captchaVal,
                    });
                    setHasSent(true);
                  } catch (err) {
                    if (setSubmitting) {
                      setSubmitting(false);
                    }

                    if (recaptchaRef.current) {
                      recaptchaRef.current.reset();
                    }
                  }

                  setSubmitting(false);
                } catch (err) {
                  setSubmitting(false);
                  // console.log('captcha err', err);
                }
              }}

            // .test(
            // 'test-phone',
            //       'We do not accept this phone number',
            //       (value) => {
            //         if (!value) {
            //           return false;
            //         }
            //         const phoneNumber = parsePhoneNumber(value);
            //         return phoneNumber.isValid();
            //       },
            //     )
            //       .transform((value, originalValue) => {
            //         const phoneNumber = parsePhoneNumber(value);
            //         if (!phoneNumber.isValid()) {
            //           return null;
            //         }
            //         return phoneNumber.number;
            //       })

              validationSchema={Yup.object().shape({
                name: Yup.string().required('Please provide your name'),
                country: Yup.string().required('Please provide your country'),
                company: Yup.string().required('Please provide your company'),
                phone: Yup.string().required('Please provide your phone'),
                email: Yup.string().required('Please provide your email'),
              })}
            >

              {({
                values, setFieldValue, handleSubmit, isSubmitting,
              }) => (
                <Form className="stdForm form-100 form-center">

                  <Fieldset type="text" placeholder="Name*" name="name" />
                  <FieldsetSelectCountry placeholder="Country*" name="country" />
                  <Fieldset type="text" placeholder="Company*" name="company" />
                  <Fieldset
                    type="tel"
                    placeholder="Phone number*"
                    name="phone"
                    onFocus={() => {
                      if (!values.phone || values.phone === '') {
                        setFieldValue('phone', '+');
                      }
                    }}
                  />
                  <Fieldset type="text" placeholder="Email*" name="email" />

                  <div className="component-connexion-section2Form-lastLine">
                    <div>*All Fields are mandatory</div>
                    <Button type="submit" className="stdBtn">register</Button>
                  </div>

                  <div className="component-connexion-captcha">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      size="invisible"
                      sitekey="6Ldcq9EZAAAAAI7TW8UyizDAMUnD1L230q4S5OBz"
                      onChange={onChangeCapcha}
                    />
                  </div>
                  {error && <div className="error smallMargin-t center">{error}</div>}
                  {isSubmitting && <Chargement withoutText />}

                </Form>
              )}
            </Formik>
          ) }

        </div>
      </section>

      <hr className="stdHr" />

      <section className="component-connexion-section3Speakers" id="section-2">
        <div className="stdContainer stdContainer-80">
          <h2 className="component-connexion-stdTitre">Speakers</h2>
          <div className="component-connexion-section3Speakers-el-container">
            <div className="component-connexion-section3Speakers-el">
              <div>Henrik Ehrnrooth</div>
              <div>CEO and President of <br />KONE Corporation</div>
            </div>
            <div className="component-connexion-section3Speakers-el">
              <div>Milla Saaristenperä</div>
              <div>Global Business Development Director, <br />KONE</div>
            </div>
            <div className="component-connexion-section3Speakers-el">
              <div>Ruban Bilen</div>
              <div>Regional Managing Director, <br /> KONE Middle East and Africa</div>
            </div>
            <div className="component-connexion-section3Speakers-el">
              <div>Ghada Othman</div>
              <div>Regional Marketing and Strategy Director, <br />  KONE Middle East and Africa</div>
            </div>
            <div className="component-connexion-section3Speakers-el">
              <div>Jan Bosman</div>
              <div>Customer Solutions Manager, <br />KONE Middle East & Africa
              </div>
            </div>
            <div className="component-connexion-section3Speakers-el">
              <div>Imad Ghanem</div>
              <div>New Business Sales Development Manager, <br /> KONE Middle East & Africa
              </div>
            </div>
            <div className="component-connexion-section3Speakers-el">
              <div>Muhammad Mahgoub</div>
              <div>Modernization Sales Development Manager, <br />  KONE Middle East & Africa
              </div>
            </div>
          </div>
        </div>
      </section>

      <hr className="stdHr stdHr-orange" />

      <section className="component-connexion-section4Ready" id="section-3">
        <div className="stdContainer stdContainer-80 stdContainer-withPadding">
          <h2 className="component-connexion-stdTitre">READY TO CONNECT</h2>

          <div>
            <Video url="https://vimeo.com/564059498" />
          </div>
        </div>
      </section>

      <hr className="stdHr stdHr-green" />

      <section className="component-connexion-section5Mission">
        <div className="stdContainer stdContainer-55  stdContainer-withPadding">

          <p><em>In an increasingly digitalized world everything is connected <br />– elevators are no exception.</em></p>
          <p>Join us to discover innovations in design and technology, be immersed in experiences that inspire, inform, and delight, be inspired with new smarter ways to work and drive value for your business and to learn about the smart connected solutions of the future, today.</p>
          <p><em>Enter a new era for elevators with <br />KONE DX - CONNECTING MORE THAN FLOORS</em></p>

          <hr className="stdHr stdHr-blue bigHugeMargin-y" />

          <div id="section-4">
            <div className="component-connexion-section5Mission-el">
              <img src={photo1} alt="photo1" />
              <div>
                <h2>MISSION</h2>
                <h3>improve the <br />flow of urban life</h3>
                <p>At KONE, our mission – the purpose which guides every decision we make – is to improve the flow of urban life. We understand urbanization and help our customers make the best of the world’s cities, buildings and public spaces. We know we can truly contribute to making cities better and more sustainable places to live.</p>
                <p>Improving the flow of urban life is a fundamentally sustainable mission. We aren’t only making cities better for ourselves, but for our children, and their children.
                </p>
              </div>
            </div>

            <div className="component-connexion-section5Mission-el component-connexion-section5Mission-el-rtl">
              <img src={photo2} alt="photo2" />
              <div>
                <h2>VISION</h2>
                <h3>create the best <br />People Flow experience</h3>
                <p>KONE’s ambition is clear. Our slightly updated vision is that KONE creates the best People Flow experience.</p>
                <p>Delivery, our previous formulation, is included in creating the best People Flow experience – but the phrase now also embeds the added elements of early engagement, co-creation, innovation, manufacture, transport, maintenance, modernization and upgrading. <br /> We wanted the term to be broader and cover the variety of actions we do when it comes to People Flow.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <hr className="stdHr" />

      <section className="component-connexion-section6recognitions" id="section-5">
        <div className="stdContainer stdContainer-60 stdContainer-withPadding">
          <h2 className="component-connexion-stdTitre">Recognitions</h2>

          <div className="component-connexion-section6recognitions-el-container">
            <div className="component-connexion-section6recognitions-el">
              <img src={recognLogo1} />
              <div className="component-connexion-section6recognitions-el-grid">
                <h4>2010 - 2016, 2018</h4>
                <h4>2017 - 2020</h4>
                <p>Among the most innovative companies in the world </p>
                <p>Among the best employers in the world</p>
              </div>
            </div>
            <div className="component-connexion-section6recognitions-el">
              <img src={recognLogo2} />
              <h4>2020</h4>
              <p>Among the top 1% of all companies rated by EcoVadis in respect to environment, labor and human rights, ethics and sustainable procurement.
              </p>
            </div>
            <div className="component-connexion-section6recognitions-el">
              <img src={recognLogo3} />
              <h4>2013 - 2020</h4>
              <p>Achieved a leadership score of A or A- for eight consecutive years, which describes our long-term commitment to environmental work and sustainability</p>
            </div>
            <div className="component-connexion-section6recognitions-el">
              <img src={recognLogo4} />
              <h4>2020</h4>
              <p>DX elevators awarded for outstanding design features, innovativeness and smart elements.
              </p>
            </div>
            <div className="component-connexion-section6recognitions-el">
              <img src={recognLogo5} />
              <h4>2015, 2019, 2020</h4>
              <p>Ranked among the world's most sustainable companies by Corporate Knights, included in the top 2% in 2020.
              </p>
            </div>
            <div className="component-connexion-section6recognitions-el">
              <img src={recognLogo6} />
              <h4>2016 - 2020</h4>
              <p>Included in the list of publicly traded companies with strong environmental, social and governance practices
              </p>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
};

Login.propTypes = {
  match: PropTypes.object,
};

Login.defaultProps = {
  match: null,
};
export default Login;
