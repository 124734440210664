import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import Popup from './Popup/Popup';

const ConfirmationPopup = ({
  onConfirm, onCancel, titre, texte, children,
}) => (
  <Popup withoutCloseBtn onClosePopup={onCancel} className="stdPopupConfirmation">
    <div className="stdPopupConfirmation-titre" dangerouslySetInnerHTML={{ __html: titre }} />
    { texte && <div className="stdPopupConfirmation-texte" dangerouslySetInnerHTML={{ __html: texte }} />}

    {children && <div className="midMargin-y">{children}</div>}
    <div className="stdPopupConfirmation-boutons">
      <Button variant="transparent" onClick={onCancel}>No</Button>
      <Button variant="" onClick={onConfirm}>Yes</Button>
    </div>

  </Popup>
);

ConfirmationPopup.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  titre: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  texte: PropTypes.string,
};

ConfirmationPopup.defaultProps = {
  children: null,
  texte: null,
  titre: 'Are you sure',
};

export default ConfirmationPopup;
