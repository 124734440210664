import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';


const FlashMessage = ({ message }) => {
  const [hasDisappear, setHasDisappear] = useState(false);
  const [isIn, setIsIn] = useState(false);
  const [willVanish, setWillVanish] = useState(false);
  const duration = 7000;
  const onClick = () => {
    setHasDisappear(true);
  };


  useEffect(() => {
    setTimeout(() => setIsIn(true), 40);
    setTimeout(() => setWillVanish(true), duration - 1000);
    setTimeout(() => {
      setHasDisappear(true);
    }, duration);
  }, []);

  if (hasDisappear) {
    return null;
  }

  return (
    <div
      onClick={onClick}
      className={clsx(
        'flashEl',
        isIn && 'flashEl-isIn',
        willVanish && 'flashEl-willVanish',
        // true && 'flashEl-isIn',
        // false && 'flashEl-willVanish',
        message.error && 'flashEl-error',
        message.variant && `flashEl-${message.variant}`,
      )}
    >
      <div>{message.titre}</div>
      { message.contenu && <div>{message.contenu}</div>}
    </div>
  );
};


FlashMessage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  message: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    titre: PropTypes.string.isRequired,
    contenu: PropTypes.string,
    variant: PropTypes.oneOf(['modele', 'mainDark', 'quintary', null]),
    error: PropTypes.bool,
  }).isRequired,
};

FlashMessage.defaultProps = {
  children: '',
};


export default FlashMessage;
