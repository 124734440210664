import {combineReducers, createStore} from 'redux';
// import * as Sentry from '@sentry/browser';
import interfaceDataReducer from './reducers/interfaceDataReducer';
import stateDataReducer from './reducers/stateDataReducer';
import {loadState, saveState} from './localStorage';


const reducers = combineReducers({
  interfaceDataReducer,
  stateDataReducer,
});

let store = null;


if (PRODUCTION || PRODUCTION === 'true'){
  // const config = {
  //   // environment: process.env.SITENAME,
  //   dsn: 'https://7847a95a69e04a258ebe6e30f6dd71d1@o141243.ingest.sentry.io/5202976',
  //   // release: 'actionnaire_frontend',
  //   release: `actionnaire_frontend@${VERSION}`,
  // };
  // if (/Edge/.test(navigator.userAgent)) {
  //   config.transport = Sentry.Transports.XHRTransport;
  // }
  // Sentry.init(config);

  store = createStore(reducers, loadState());
  // store = createStore(reducers, loadState(), applyMiddleware(createSentryMiddleware(Sentry, {})));
} else{
  store = createStore(reducers, loadState(), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
}


store.subscribe(() => {
  saveState(store.getState());
});


export default store;
