import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Portal from './Portal';


const HoverPortal = ({
  children, portalComponent, ...props
}) => {
  const [show, setShow] = useState(false);

  const refParent = useRef();
  const refPortal = useRef();

  const handleMouseMove = (e) => {
    if (!refPortal.current || !refParent.current) {
      return;
    }
    if (!refPortal.current.contains(e.target) && !refParent.current.contains(e.target)) {
      setShow(false);
      document.removeEventListener('mousemove', handleMouseMove);
    }
  };

  const mouseOver = () => {
    if (!show) {
      setShow(true);
      document.addEventListener('mousemove', handleMouseMove);
    }
  };


  //Clean properly
  useEffect(() => () => {
    document.removeEventListener('mousemove', handleMouseMove);
  }, []);


  return (
    <Portal
      {...props}
      show={show}
      onMouseOver={mouseOver}
      portalComponent={portalComponent}
      setPortalRef={(ref) => refPortal.current = ref.current}
      setParentRef={(ref) => refParent.current = ref.current}
    >
      {children}
    </Portal>
  );
};


HoverPortal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  portalComponent: PropTypes.node.isRequired,
};

HoverPortal.defaultProps = {
};

export default HoverPortal;
