import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Chargement = ({
  children, allHeight, height50, withoutText, ...props
}) => (
  <div className={clsx('stdChargement',
    allHeight && 'stdChargement-allHeight',
    height50 && 'stdChargement-height50')}
  >
    <div className="stdChargement-ripple">
      <div />
      <div />
    </div>
    { !withoutText && <div>{children}</div>}
  </div>
);

Chargement.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  allHeight: PropTypes.bool,
  height50: PropTypes.bool,
  withoutText: PropTypes.bool,
};

Chargement.defaultProps = {
  allHeight: false,
  height50: false,
  withoutText: false,
  children: 'Loading',
};

export default Chargement;
