import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import useInterface from '../Lib/useInterface';
import { PAGE_LIVE, PAGE_LOBBY } from './Header/Header';
import Bus from '../Lib/misc/Bus';
import Button from '../components/UI/Button';
import toLobby from '../../img/KO/Videos/toLobby.mp4';
import toConnectivity from '../../img/KO/Videos/toConnectivity.mp4';
import toExperiences from '../../img/KO/Videos/toExperiences.mp4';
import toPartnership from '../../img/KO/Videos/toPartnership.mp4';
import isMobile from '../Lib/misc/isMobile';
import { TRANSITION_VIDEO_STATE_FINISHED, TRANSITION_VIDEO_STATE_PLAYING } from './MainSpace/TopWindow';

const PANORAMA_PAGE_EMPTY = 'empty';

const PANORAMA_PAGE_LOBBY_LIVE = 'lobby_live';
const PANORAMA_PAGE_LOBBY_LOBBY1 = 'scene_lobby1';
const PANORAMA_PAGE_LOBBY_DXTRAILERFILM1 = 'lobby_dxTrailerFilm1';
const PANORAMA_PAGE_LOBBY_DXINBRIEF2 = 'lobby_dxInBrief2';
const PANORAMA_PAGE_LOBBY_TIMELINE3 = 'lobby_timeline3';
const PANORAMA_PAGE_LOBBY_DXTRAILERFILM4 = 'lobby_dxTrailerFilm4';
const PANORAMA_PAGE_ZONE1_SPOT1 = 'zone1_spot1';
const PANORAMA_PAGE_ZONE1_SPOT2 = 'zone1_spot2';
const PANORAMA_PAGE_ZONE1_SPOT21 = 'zone1_spot21';
const PANORAMA_PAGE_ZONE1_SPOT22 = 'zone1_spot22';
const PANORAMA_PAGE_ZONE1_SPOT23 = 'zone1_spot23';
const PANORAMA_PAGE_ZONE1_SPOT3 = 'zone1_spot3';
const PANORAMA_PAGE_ZONE1_SPOT4 = 'zone1_spot4';
const PANORAMA_PAGE_ZONE2_SPOT1 = 'zone2_spot1';
const PANORAMA_PAGE_ZONE2_SPOT2 = 'zone2_spot2';
const PANORAMA_PAGE_ZONE2_SPOT3 = 'zone2_spot3';
const PANORAMA_PAGE_ZONE2_SPOT4 = 'zone2_spot4';
const PANORAMA_PAGE_ZONE2_SPOT5 = 'zone2_spot5';
const PANORAMA_PAGE_ZONE3_SPOT1 = 'zone3_spot1';
const PANORAMA_PAGE_ZONE3_SPOT2 = 'zone3_spot2';
const PANORAMA_PAGE_ZONE3_SPOT3 = 'zone3_spot3';
const PANORAMA_PAGE_ZONE3_SPOT4 = 'zone3_spot4';
const PANORAMA_PAGE_ZONE3_SPOT5 = 'zone3_spot5';

const Panorama = ({ onSetPanoramaPage, transitionVideoState, setTransitionVideoState }) => {
  const transitionVideoRef = useRef();

  const [isReady, setIsReady] = useState(false);

  const currentSceneInPanorama = useSelector((state) => state.interfaceDataReducer.currentSceneInPanorama);
  const currentSceneInPanoramaRef = useRef();

  const { changePage, setCurrentSceneInPanorama, setShowTrailer } = useInterface();

  useEffect(() => {
    currentSceneInPanoramaRef.current = currentSceneInPanorama;
  }, [currentSceneInPanorama]);

  const launchVideo = (newSrc) => {
    setShowTrailer(true);

    if (!isMobile()) {
      setTransitionVideoState(TRANSITION_VIDEO_STATE_PLAYING);
      if (transitionVideoRef.current) {
        transitionVideoRef.current.src = newSrc;
        transitionVideoRef.current.defaultMuted = true;
        transitionVideoRef.current.muted = true;
        transitionVideoRef.current.play();
      }
    }
  };

  useEffect(() => {
    window.sendBackEvent = (eventName, arg) => {
      // console.log(eventName);
      const oldValueCurrentSceneInPanoramaRef = currentSceneInPanoramaRef.current;

      if (eventName === 'sceneChange') {
        setCurrentSceneInPanorama(arg);
        currentSceneInPanoramaRef.current = arg;
        if (arg === 'scene_lobby1' && (!oldValueCurrentSceneInPanoramaRef || oldValueCurrentSceneInPanoramaRef === 'scene_ascenseur')) {
          launchVideo(toLobby);
          onSetPanoramaPage(arg);
        } else if (arg.search('zone1') > -1 && oldValueCurrentSceneInPanoramaRef.search('zone1') === -1) {
          launchVideo(toExperiences);
        } else if (arg.search('zone2') > -1 && oldValueCurrentSceneInPanoramaRef.search('zone2') === -1) {
          launchVideo(toPartnership);
        } else if (arg.search('zone3') > -1 && oldValueCurrentSceneInPanoramaRef.search('zone3') === -1) {
          launchVideo(toConnectivity);
        }
        // else if (arg === 'scene_lobby1') {
        //   launchVideo(toStage);
        // }
        return;
      }

      if (eventName === PANORAMA_PAGE_LOBBY_LIVE) {
        changePage(PAGE_LIVE);
      } else if (eventName === PANORAMA_PAGE_EMPTY) {
        changePage(PAGE_LOBBY);
      } else {
        onSetPanoramaPage(eventName);
      }
    };

    window.setTimeout(() => {
      setIsReady(true);
      if (typeof (document.getElementById('pano')) !== 'undefined' && document.getElementById('pano') != null) {
        embedpano({
          swf: 'krpano/tour.swf', xml: 'krpano/tour.xml', target: 'pano', html5: 'auto', mobilescale: 0.5, passQueryParameters: true, debugmode: true, consolelog: false, mwheel: false, capturetouch: false,
        });
      }
    }, 500);

    Bus.addListener('krpanoCommand', ({ message }) => {
      const krpano = document.getElementById('krpanoSWFObject');
      krpano.call(`changeSceneFromOutside(${message})`);
    });

    return () => {
      window.sendBackEvent = null;
    };
  }, []);

  const shouldDisplayBackBtn = () => currentSceneInPanorama && (
    currentSceneInPanorama.toLowerCase().indexOf('library') > -1
    || currentSceneInPanorama.toLowerCase().indexOf('scene_ff2021_lobby_2_-_panorama') > -1
    || currentSceneInPanorama.toLowerCase().indexOf('scene_ff2021_lobby_3_-_panorama') > -1
  );

  return (
    <>
      <div className="component-panorama">
        <div className={clsx('component-panorama-transitionVideo', `component-panorama-transitionVideo-${transitionVideoState}`)}>
          <video
            playsInline
            muted
            ref={transitionVideoRef}
            onEnded={() => { setTransitionVideoState(TRANSITION_VIDEO_STATE_FINISHED); }}
          >
            <source type="video/mp4" />
          </video>
        </div>

        { shouldDisplayBackBtn() && <Button onClick={() => { window.krpanoCommand('plenary'); }}>Back to lobby</Button>}
        <div id="pano" />

      </div>
    </>
  );
};

Panorama.propTypes = {
  onSetPanoramaPage: PropTypes.func.isRequired,
};

Panorama.defaultProps = {
};

export {
  PANORAMA_PAGE_LOBBY_LIVE,
  PANORAMA_PAGE_LOBBY_LOBBY1,
  PANORAMA_PAGE_LOBBY_DXTRAILERFILM1,
  PANORAMA_PAGE_LOBBY_DXINBRIEF2,
  PANORAMA_PAGE_LOBBY_TIMELINE3,
  PANORAMA_PAGE_LOBBY_DXTRAILERFILM4,
  PANORAMA_PAGE_ZONE1_SPOT1,
  PANORAMA_PAGE_ZONE1_SPOT2,
  PANORAMA_PAGE_ZONE1_SPOT21,
  PANORAMA_PAGE_ZONE1_SPOT22,
  PANORAMA_PAGE_ZONE1_SPOT23,
  PANORAMA_PAGE_ZONE1_SPOT3,
  PANORAMA_PAGE_ZONE1_SPOT4,
  PANORAMA_PAGE_ZONE2_SPOT1,
  PANORAMA_PAGE_ZONE2_SPOT2,
  PANORAMA_PAGE_ZONE2_SPOT3,
  PANORAMA_PAGE_ZONE2_SPOT4,
  PANORAMA_PAGE_ZONE2_SPOT5,
  PANORAMA_PAGE_ZONE3_SPOT1,
  PANORAMA_PAGE_ZONE3_SPOT2,
  PANORAMA_PAGE_ZONE3_SPOT3,
  PANORAMA_PAGE_ZONE3_SPOT4,
  PANORAMA_PAGE_ZONE3_SPOT5,
};

export default Panorama;
