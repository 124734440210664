import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Redirect, Route, Switch,
} from 'react-router';
import Header, { PAGE_CONCLAVE } from './Header/Header';
import MainSpace from './MainSpace/MainSpace';
import useSecurity from '../Lib/useSecurity';
import StaffSpace from './StaffSpace/StaffSpace';
import Cookies from './Cookies';
import SwitchUserPannel from './SwitchUserPannel';
import useInterface from '../Lib/useInterface';
import ErrorBoundary from './ErrorBoundary/ErrorBoundary';
import LoginStaff from './Login/LoginStaff';
import RegisterEmail from './RegisterEmail';

let connection = null;

const LoggedApp = () => {
  const email = useSelector((state) => state.interfaceDataReducer.email);
  const loggedUtilisateur = useSelector((state) => state.stateDataReducer.loggedUtilisateur);
  const currentPage = useSelector((state) => state.interfaceDataReducer.currentPage);
  const { changePage } = useInterface();
  const { isStaff, isConcierge } = useSecurity();

  useEffect(() => {
    if (!currentPage && isConcierge()) {
      changePage(PAGE_CONCLAVE);
    }
  }, [currentPage]);

  useEffect(() => {
    if (!loggedUtilisateur) {
      return;
    }
    window.setTimeout(() => {
      connection = new signalR.HubConnectionBuilder()
        .withUrl(`https://ptldemo.azurewebsites.net/traffichub?token=${email}`)
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();

      async function start() {
        try {
          await connection.start();
          // console.log('SignalR Connected.');
        } catch (err) {
          // console.log(err);
          setTimeout(start, 5000);
        }
      }
      connection.onclose(start);
      start();
    }, 1000);
  }, [loggedUtilisateur]);

  const beforeLogout = async () => {
    await connection.stop();
    // console.log('SignalR Disconnected');
  };

  return (
    <BrowserRouter>
      <Switch>

        <Route exact path="/connect-staff" render={() => (<ErrorBoundary><LoginStaff /></ErrorBoundary>)} />
        <Route
          path="/"
          render={() => (
            <ErrorBoundary>{ (!email && !loggedUtilisateur) ? <RegisterEmail /> : (
              <>
                { (() => {
                  if (isStaff()) {
                    return <StaffSpace />;
                  }
                  return <><Header beforeLogout={beforeLogout} /><MainSpace /></>;
                })()}

                <SwitchUserPannel />
                <Cookies />
              </>
            )}
            </ErrorBoundary>
          )}
        />
        <Redirect from="*" to="/" />

      </Switch>
    </BrowserRouter>
  );
};

export default LoggedApp;
