import React, { useState } from 'react';
import clsx from 'clsx';
import DateTime from 'luxon/src/datetime';
import useRestClient from '../../Lib/useRestClient';
import Chargement from '../../components/UI/Chargement';
import ProgramProgramme from './ProgramProgramme';
import isMobile from '../../Lib/misc/isMobile';
import LiveSondage from '../Live/LiveSondage';

const SmallProgram = () => {
  const [evenement] = useRestClient('evenements/1');
  if (!evenement) {
    return <Chargement />;
  }

  const today = DateTime.local();

  const nDay = Math.round(DateTime.local().diff(evenement.date, ['days']).toObject().days) - 1;

  const programmesThisDay = evenement.programmes.filter(({ jour }) => jour === nDay);

  return (
    <div className={clsx('component-smallProgram')}>
      <div className="stdTab">
        <div className="stdTab-titre">Program overview</div>
        <div className="stdTab-content">
          <h3>{ today.toFormat('DDDD')}</h3>
          {/*<h2>PILLAR: LIVE & MOVE</h2>*/}
          <div className="component-smallProgram-table">
            {programmesThisDay.filter(({ bHideInProgram }) => !bHideInProgram).map(({
              id, name, jour, heure, duree,
            }, index) => {
              const hour = DateTime.fromFormat(heure, 'HH:mm');
              const start = evenement.date.plus({ days: jour }).set({ hour: hour.toFormat('HH'), minute: hour.toFormat('mm') });
              const end = start.plus({ minutes: duree });

              // const isNow = index === 3;
              const isNow = DateTime.local() > start && DateTime.local() < end;


              return (
                <div key={id} className={clsx(isNow && 'active')}>
                  {isNow && <div>Livestreaming now:</div>}
                  <div>
                    <div>
                      <div>{start.toFormat('HH:mm a')}- <br /> {end.toFormat('HH:mm a')}</div>
                      <div>Total: {duree}&nbsp;mins</div>
                    </div>
                    <div>
                      <div>{name} </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <LiveSondage />

    </div>
  );
};

SmallProgram.propTypes = {};

SmallProgram.defaultProps = {};
export default SmallProgram;
