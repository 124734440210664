import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import YouTube from 'react-youtube';
import Vimeo from '@u-wave/react-vimeo';
import { getCodeFromYoutubeUrl } from '../../Lib/misc/youtubeMisc';

const Video = ({
  url, variant, autoPlay, onFinish, ...props
}) => {
  let component = null;

  if (url.search('vimeo') > -1) {
    component = (
      <Vimeo
        video={url}
        autoplay={autoPlay}
        width="640"
        height="500"
        onEnd={onFinish}
        {...props}
      />
    );
  } else {
    component = (
      <YouTube
        videoId={getCodeFromYoutubeUrl(url)}
        onEnd={onFinish}
        opts={{
          width: '640',
          height: '500',
          playerVars: {
            autoplay: autoPlay,
            allowFullScreen: true,
          },
        }}
      />
    );
  }

  return (
    <div className={clsx(
      'stdVideo',
      variant && variant.split(' ').map((el) => `stdVideo-${el}`),
    )}
    >  {component}
    </div>
  );
};

Video.propTypes = {
  url: PropTypes.string.isRequired,
  autoPlay: PropTypes.bool,
  variant: PropTypes.string,
  onFinish: PropTypes.func,
};

Video.defaultProps = {
  autoPlay: false,
  variant: null,
  onFinish: () => {},
};

export default Video;
