import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DateTime from 'luxon/src/datetime';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import Button from '../../components/UI/Button';
import ProgramSpeaker from './ProgramSpeaker';
import SpeakerDesc from '../../components/UI/SpeakerDesc';
import useRestClient from '../../Lib/useRestClient';
import Chargement from '../../components/UI/Chargement';
import BookmarkIcon from '../../components/UI/BookmarkIcon';

const DateForProgramme = ({ programme }) => {
  if (!programme.realDateDebut || !programme.duree) {
    return null;
  }
  return (
    <div>
      <div>{programme.realDateDebut.toFormat('DD')}</div>
      <div>{programme.realDateDebut.toFormat('HH:mm')} - {programme.realDateFin.toFormat('HH:mm ZZZZ')}</div>
      {/* <div>{programme.duree} mins</div> */}
    </div>
  );
};

const ProgramProgramme = ({ programmeId, onBack, forMobile }) => {
  const [lastAction, setLastAction] = useState(DateTime.local());
  const [selectedUtilisateurId, setSelectedUtilisateurId] = useState(null);

  const loggedUtilisateur = useSelector((state) => state.stateDataReducer.loggedUtilisateur);

  const [programme] = useRestClient(`programmes/${programmeId}`, null, [lastAction]);
  if (!programme) {
    return <Chargement height50 />;
  }

  if (selectedUtilisateurId) {
    return <ProgramSpeaker utilisateurId={selectedUtilisateurId} onBack={() => setSelectedUtilisateurId(null)} />;
  }

  return (
    <div className={clsx('component-programProgramme', forMobile && 'component-programProgramme-forMobile')}>

      <h1>About this session</h1>
      <div className="component-programProgramme-top">
        <DateForProgramme programme={programme} />

        <div />

        <div>
          <h2>{programme.name}</h2>
        </div>
        {/*  <BookmarkIcon */}
        {/*  className="inbl big smallMargin-r" */}
        {/*  hasBookmarked={loggedUtilisateur.bookmarkedProgrammesId && loggedUtilisateur.bookmarkedProgrammesId.includes(programme.id)} */}
        {/*  onChange={() => { */}
        {/*    setLastAction(DateTime.local()); */}
        {/*  }} */}
        {/*  programme={programme} */}
        {/* /> */}

      </div>
      {/*<BookmarkIcon*/}
      {/*  withText*/}
      {/*  small*/}
      {/*  hasBookmarked={loggedUtilisateur.bookmarkedProgrammesId && loggedUtilisateur.bookmarkedProgrammesId.includes(programme.id)}*/}
      {/*  className="floatRight hideTablet"*/}
      {/*  onChange={() => {*/}
      {/*    setLastAction(DateTime.local());*/}
      {/*  }}*/}
      {/*  programme={programme}*/}
      {/*/>*/}

      <div className="component-programProgramme-bot">

        {/*<p className="wysiwyg" dangerouslySetInnerHTML={{ __html: 'vierium Cursuss mori in placidus aetheres!' }} />*/}
         <p className="wysiwyg" dangerouslySetInnerHTML={{ __html: programme.description }} />

        <div>
          <div>
            <h3>Speakers</h3>
            <div className="component-programProgramme-bot-el-container">
              { programme.programmeSpeakers.length ? programme.programmeSpeakers.map(({ speaker }, key) => (
                <div key={key} onClick={() => setSelectedUtilisateurId(speaker.id)} className="component-programProgramme-bot-el">
                  <SpeakerDesc small utilisateur={speaker} />
                </div>
              )) : <div>No speakers at this time.</div>}
            </div>
          </div>
          <div>
            <h3>Moderator</h3>
            <div className="component-programProgramme-bot-el-container">
              { programme.programmeModerators.length ? programme.programmeModerators.map(({ moderator }, key) => (
                <div key={key} onClick={() => setSelectedUtilisateurId(moderator.id)} className="component-programProgramme-bot-el">
                  <SpeakerDesc small utilisateur={moderator} />
                </div>
              )) : <div>No moderators at this time.</div>}
            </div>
          </div>
        </div>


        <div className="rightAlign">
          <Button onClick={onBack}>Back to program</Button>
        </div>

      </div>

    </div>
  );
};

ProgramProgramme.propTypes = {
  programmeId: PropTypes.number.isRequired,
  onBack: PropTypes.func.isRequired,
  forMobile: PropTypes.bool,
};

ProgramProgramme.defaultProps = {
  forMobile: false,
};
export default ProgramProgramme;
