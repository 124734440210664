import React, { Component } from 'react';
// import * as Sentry from '@sentry/browser';
import ErrorBoundaryContenu from './ErrorBoundaryContenu';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    // Sentry.captureException(error, { extra: errorInfo });
  }

  render() {
    if (this.state.error) {
      return <ErrorBoundaryContenu error={this.state.error} />;
    }
    return this.props.children;
  }
}


export default ErrorBoundary;
