import { getIn } from 'formik';
import PropTypes from 'prop-types';

const HasErrorIsTouched = ({
  form, field, children,
}) => {
  const hasError = !!getIn(form.errors, field.name);
  const isTouched = !!getIn(form.touched, field.name);
  return children(hasError, isTouched);
};


HasErrorIsTouched.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  children: PropTypes.func.isRequired,
};

HasErrorIsTouched.defaultProps = {};
export default HasErrorIsTouched;
