import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';


const SplitDiv = ({
  children, className, ...props
}) => (
  <div className={clsx('stdSplitDiv', `stdSplitDiv-${children.length}`, className)} {...props}>{children}</div>
);


SplitDiv.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
};

SplitDiv.defaultProps = {
  children: null,
  className: null,
};

export default SplitDiv;
