import axios from 'axios';

const getCodeFromYoutubeUrl = (yUrl) => {
  let videoId = yUrl.split('v=')[1];
  if (!videoId) {
    return null;
  }
  let ampersandPosition = videoId.indexOf('&');
  if (ampersandPosition === -1) {
    ampersandPosition = videoId.indexOf('?');

  }

  if (ampersandPosition !== -1){
    videoId = videoId.substring(0, ampersandPosition);
  }
  return videoId;
};

const getCodeFromVimeoUrl = (yUrl) => {
  const match = yUrl.match(/https?:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/);

  if (match) {
    return match[2];
  }
  return null;
};

const getVideoThumbUrl = (url) => {
  if (url.search('vimeo') > -1) {
    return null;
  }

  return `https://img.youtube.com/vi/${getCodeFromYoutubeUrl(url)}/0.jpg`;
};

export { getCodeFromYoutubeUrl, getCodeFromVimeoUrl, getVideoThumbUrl };
