const EvenementState = {
  UNSTARTED: -1,
  STARTED: 0,
  FINISHED: 1,
};
const arEvenementStates = [
  { value: EvenementState.UNSTARTED, label: 'Unstarted' },
  { value: EvenementState.STARTED, label: 'Started' },
  { value: EvenementState.FINISHED, label: 'Finished' },
];
export { arEvenementStates };
export default EvenementState;
