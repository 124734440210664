import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import * as InterfaceAction from '../../Redux/actions/Interface';
import logo from '../../../img/KO/logo.svg';
import Fieldset from '../../components/UI/Form/Fieldset';
import Button from '../../components/UI/Button';
import Chargement from '../../components/UI/Chargement';

const LoginStaff = ({}) => {
  const recaptchaRef = useRef();
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const tryConnect = async (password, captcha, setSubmitting = null) => {
    try {
      await axios.post('login_check', {
        username: password,
        password,
        captcha,
      });
      window.location.href = '/chargement';
    } catch (err) {
      if (setSubmitting) {
        setSubmitting(false);
      }
      if (err.response.data.code === 444) {
        setError('Your ip is not whitelisted');
      } else {
        setError('Please verify your password');
      }

      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
    }
  };

  return (
    <div className="component-registerEmail">
      <img src={logo} alt="Kone" />
      <div className="component-registerEmail-content">
        <Formik
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const captchaVal = await recaptchaRef.current.executeAsync();
              setError(null);
              tryConnect(values.password, captchaVal, setSubmitting);
            } catch (err) {
              setSubmitting(false);
              // console.log('captcha err', err);
            }
          }}
          initialValues={{
            password: '',
          }}

          validationSchema={Yup.object().shape({
            password: Yup.string().required('Please provide a password').typeError('Please provide a password'),
          })}
        >

          {({
            values, setFieldValue, handleSubmit, isSubmitting,
          }) => (
            <Form className="stdForm form-100 form-center center">
              <div className="midMargin-y">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey="6Ldcq9EZAAAAAI7TW8UyizDAMUnD1L230q4S5OBz"
                />
                <Fieldset name="password" type="password" placeholder="PASSWORD">Enter your password</Fieldset>
              </div>
              <br />
              <Button type="submit" className="stdBtn">Submit</Button>
              {error && <div className="error smallMargin-t center">{error}</div>}
              {isSubmitting && <><br /><br /><Chargement withoutText /></>}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

LoginStaff.propTypes = {};

LoginStaff.defaultProps = {};
export default LoginStaff;
