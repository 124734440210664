import { useEffect, useState } from 'react';

const useScroll = () => {
  const [scrollTop, setScrollTop] = useState(0);
  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.scrollTop);
    };
    document.getElementById('root').addEventListener('scroll', onScroll);
    return () => document.getElementById('root').removeEventListener('scroll', onScroll);
  }, [scrollTop]);

  return scrollTop;
};

export default useScroll;
