import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from './Checkbox';

const CheckboxWithLabel = ({
  children, onClick, ...props
}) => (
  <button className="stdCheckbox-withLabel" onClick={onClick}>
    <Checkbox divVersion {...props} />
    <div>{children}</div>
  </button>
);

CheckboxWithLabel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  onClick: PropTypes.func.isRequired,
};

CheckboxWithLabel.defaultProps = {};

export default CheckboxWithLabel;
