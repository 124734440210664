import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, Field, getIn } from 'formik';
import clsx from 'clsx';
import Input from './Input';
import HasErrorIsTouched from './HasErrorIsTouched';
import InputWithIcon from './InputWithIcon';

const Fieldset = ({
  children, className, name, input, icon, afterInputChange, ...props
}) => (
  <Field name={name} {...props}>
    {({ field, form }) => (
      <fieldset className={clsx(
        'stdFieldset',
        className,
        getIn(form.errors, field.name) && 'stdFieldset-error',
      )}
      >
        {children && <label htmlFor={name} className="stdLabel">{children}</label>}
        <HasErrorIsTouched field={field} form={form}>
          {(hasError, isTouched) => input({
            value: field.value,
            ...field,
            ...props,
            hasError,
            isTouched,
            onChange: ((newVal) => {
              form.setFieldValue(name, newVal);
              afterInputChange(newVal);
            }),
          }, icon)}
        </HasErrorIsTouched>
        <ErrorMessage name={name} component="div" className="error" />
      </fieldset>
    )}
  </Field>
);

Fieldset.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  icon: PropTypes.string,
  input: PropTypes.func,
  afterInputChange: PropTypes.func,
};

Fieldset.defaultProps = {
  children: null,
  input: (fieldProp, icon) => <InputWithIcon {...fieldProp} icon={icon} />,
  className: null,
  icon: null,
  afterInputChange: () => {
  },
};

export default Fieldset;
