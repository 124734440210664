import React, { useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import Panorama, {
  PANORAMA_PAGE_LOBBY_DXINBRIEF2,
  PANORAMA_PAGE_LOBBY_DXTRAILERFILM1,
  PANORAMA_PAGE_LOBBY_DXTRAILERFILM4, PANORAMA_PAGE_LOBBY_LOBBY1,
  PANORAMA_PAGE_LOBBY_TIMELINE3,
  PANORAMA_PAGE_ZONE1_SPOT1,
  PANORAMA_PAGE_ZONE1_SPOT2,
  PANORAMA_PAGE_ZONE1_SPOT21,
  PANORAMA_PAGE_ZONE1_SPOT22,
  PANORAMA_PAGE_ZONE1_SPOT23,
  PANORAMA_PAGE_ZONE1_SPOT3,
  PANORAMA_PAGE_ZONE1_SPOT4,
  PANORAMA_PAGE_ZONE2_SPOT1,
  PANORAMA_PAGE_ZONE2_SPOT2, PANORAMA_PAGE_ZONE2_SPOT3, PANORAMA_PAGE_ZONE2_SPOT4, PANORAMA_PAGE_ZONE2_SPOT5, PANORAMA_PAGE_ZONE3_SPOT1, PANORAMA_PAGE_ZONE3_SPOT2, PANORAMA_PAGE_ZONE3_SPOT3, PANORAMA_PAGE_ZONE3_SPOT4, PANORAMA_PAGE_ZONE3_SPOT5,
} from '../Panorama';
import { PAGE_FEEDBACK, PAGE_LIVE, PAGE_LOBBY } from '../Header/Header';
import { LiveWithChat } from '../Live/Live';
import ButtonClose from '../../components/UI/ButtonClose';
import useInterface from '../../Lib/useInterface';
import useBackButton from '../../Lib/useBackButton';
import Video from '../../components/UI/Video';
import LiveThumbnail from '../Live/LiveThumbnail';
import PopupFeedback from '../PopupFeedback';
import KoneTopPanoramaPage from './KoneTopPanoramaPage';
import ChatBulle from './ChatBulle';
import useKey from '../../Lib/useKey';

const TopWindowVideoPopup = ({ setCurrentTopPanoramaPage, url, withBack }) => {
  const { setShowTrailer } = useInterface();
  const currentSceneInPanorama = useSelector((state) => state.interfaceDataReducer.currentSceneInPanorama);

  let goodUrl = url;
  if (url === 'getFromTrailer') {
    switch (currentSceneInPanorama) {
      case 'scene_zone1_1': { // Experiences
        goodUrl = 'https://player.vimeo.com/video/568132440';
        break;
      }
      case 'scene_zone2_1': { // Partnership
        goodUrl = 'https://player.vimeo.com/video/568470999';
        break;
      }
      case 'scene_zone3_0': // Connectivity
      case 'scene_zone3_5': {
        goodUrl = 'https://player.vimeo.com/video/568132349';
        break;
      }

      default: {
        setShowTrailer(false);
        setCurrentTopPanoramaPage(null);
      }
    }
  }

  // "https://player.vimeo.com/video/564313855"

  useBackButton(() => setCurrentTopPanoramaPage());
  return (
    <>
      <ButtonClose
        backgrounded
        onClick={() => {
          setShowTrailer(false);
          setCurrentTopPanoramaPage(null);
        }}
      />
      <Video
        url={goodUrl}
        autoPlay
        onFinish={() => {
          setShowTrailer(false);
          setCurrentTopPanoramaPage(null);
        }}
      />
    </>
  );
};

const TRANSITION_VIDEO_STATE_STOPPED = 'stopped';
const TRANSITION_VIDEO_STATE_PLAYING = 'playing';
const TRANSITION_VIDEO_STATE_FINISHED = 'finished';

const TopWindow = () => {
  const currentPage = useSelector((state) => state.interfaceDataReducer.currentPage);
  const showTrailer = useSelector((state) => state.interfaceDataReducer.showTrailer);

  const config = useSelector((state) => state.stateDataReducer.config);

  const isFinished = config && parseInt(config.isFinished) === 1;
  const [transitionVideoState, setTransitionVideoState] = useState(TRANSITION_VIDEO_STATE_STOPPED);
  const [currentTopPanoramaPage, setCurrentTopPanoramaPage] = useState(null);
  const [showProgram, setShowProgram] = useState(false);
  const { changePage } = useInterface();

  useKey({
    keyCode: 32,
    callback: () => {
      if (transitionVideoState === TRANSITION_VIDEO_STATE_PLAYING) {
        setTransitionVideoState(TRANSITION_VIDEO_STATE_FINISHED);
      }
    },
  });

  const getAddedClasses = () => {
    if (currentPage === PAGE_LIVE) {
      return 'stdMainSpace-topWindow-overlayPage-forLive';
    }
    if (showTrailer) {
      return 'stdMainSpace-topWindow-overlayPage-video';
    }
    if ([
      PANORAMA_PAGE_LOBBY_LOBBY1,
      PANORAMA_PAGE_LOBBY_DXTRAILERFILM1,
      PANORAMA_PAGE_LOBBY_DXINBRIEF2,
      PANORAMA_PAGE_LOBBY_TIMELINE3,
      PANORAMA_PAGE_LOBBY_DXTRAILERFILM4,
      PANORAMA_PAGE_ZONE1_SPOT1,
      PANORAMA_PAGE_ZONE1_SPOT2,
      PANORAMA_PAGE_ZONE1_SPOT21,
      PANORAMA_PAGE_ZONE1_SPOT22,
      PANORAMA_PAGE_ZONE1_SPOT23,
      PANORAMA_PAGE_ZONE1_SPOT3,
      PANORAMA_PAGE_ZONE1_SPOT4,
      PANORAMA_PAGE_ZONE2_SPOT1,
      PANORAMA_PAGE_ZONE2_SPOT2,
      PANORAMA_PAGE_ZONE2_SPOT3,
      PANORAMA_PAGE_ZONE2_SPOT4,
      PANORAMA_PAGE_ZONE2_SPOT5,
      PANORAMA_PAGE_ZONE3_SPOT1,
      PANORAMA_PAGE_ZONE3_SPOT2,
      PANORAMA_PAGE_ZONE3_SPOT3,
      PANORAMA_PAGE_ZONE3_SPOT4,
      PANORAMA_PAGE_ZONE3_SPOT5,
    ].includes(currentTopPanoramaPage)) {
      return 'stdMainSpace-topWindow-overlayPage-forSlideshow';
    }
  };
  return (
    <>
      <div className={clsx('stdMainSpace-topWindow')}>
        <>
          <div className={clsx('stdMainSpace-topWindow-overlayPage', getAddedClasses())}>
            {(() => {
              if (currentPage === PAGE_LIVE) {
                return (
                  <>
                    <div>
                      <ButtonClose
                        backgrounded
                        onClick={() => {
                          changePage(PAGE_LOBBY);
                        }}
                      />
                      <LiveWithChat showProgram={showProgram} />

                      {/* {showProgram ? <div className="component-program-outline"><Program withBackground onClose={() => setShowProgram(false)} /></div> : ( */}
                      {/*  <div className="component-live-showProgramDiv"> */}
                      {/*    <Button onClick={() => setShowProgram(true)} variant="big">Show program</Button> */}
                      {/*  </div> */}
                      {/* )} */}
                    </div>
                  </>
                );
              }

              if (showTrailer && transitionVideoState !== TRANSITION_VIDEO_STATE_PLAYING) {
                return (
                  <TopWindowVideoPopup
                    setCurrentTopPanoramaPage={setCurrentTopPanoramaPage}
                    onClose={() => {
                      setCurrentTopPanoramaPage(null);
                    }}
                    url="getFromTrailer"
                  />
                );
              }

              if (currentPage === PAGE_FEEDBACK) {
                return <PopupFeedback onClose={() => { changePage(PAGE_LOBBY); }} />;
              }
              if (currentTopPanoramaPage) {
                return <KoneTopPanoramaPage currentTopPanoramaPage={currentTopPanoramaPage} onClose={() => setCurrentTopPanoramaPage(null)} />;
              }
            })()}

          </div>
          {currentPage === PAGE_LIVE && <div className="stdMainSpace-topWindow-overlayPage-stdOverlay" />}
        </>
        {/*{currentPage !== PAGE_LIVE && <LiveThumbnail />}*/}
        {/*<ChatBulle />*/}

        {(() => {
          switch (currentPage) {
            default:
            case PAGE_LIVE:
            case PAGE_LOBBY: {
              return <Panorama onSetPanoramaPage={setCurrentTopPanoramaPage} transitionVideoState={transitionVideoState} setTransitionVideoState={setTransitionVideoState} />;
            }
          }
        })()}
      </div>
    </>
  );
};

export { TRANSITION_VIDEO_STATE_STOPPED, TRANSITION_VIDEO_STATE_PLAYING, TRANSITION_VIDEO_STATE_FINISHED };
export default TopWindow;
