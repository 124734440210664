import React, { useRef, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import logo from '../../../img/KO/logo.svg';
import handler from '../../../img/KO/handler.svg';
import linkedin from '../../../img/KO/Social/in.svg';
import fb from '../../../img/KO/Social/fb.svg';
import twitter from '../../../img/KO/Social/twitter.svg';
import insta from '../../../img/KO/Social/insta.svg';
import useInterface from '../../Lib/useInterface';
import LoginPopup from './LoginPopup';
import EditProfile from './EditProfile';
import DropdowMenu from '../../components/UI/Menu/DropdowMenu';
import ButtonClose from '../../components/UI/ButtonClose';
import isMobile from '../../Lib/misc/isMobile';

const PAGE_LIVE = 'live';
const PAGE_LOBBY = 'lobby';
const PAGE_FEEDBACK = 'feedback';

const Header = ({ beforeLogout }) => {
  const currentSceneInPanorama = useSelector((state) => state.interfaceDataReducer.currentSceneInPanorama);
  const [editProfile, setEditProfile] = useState(false);
  const [willLogin, setWillLogin] = useState(false);
  const [showZones, setShowZones] = useState(false);
  const currentPage = useSelector((state) => state.interfaceDataReducer.currentPage);
  const [actResp, setActResp] = useState(false);
  const [act, setAct] = useState(false);

  const history = useHistory();
  const { moveToPanoScene, changePage } = useInterface();

  const headerMoveToPanoScene = (scene) => {
    setActResp(false);
    moveToPanoScene(scene);
    changePage(PAGE_LOBBY);
  };

  return (
    <>
      { editProfile && <EditProfile onClosePopup={() => setEditProfile(false)} /> }
      <header className={clsx('stdHeader', actResp && 'stdHeader-actResp', !act && 'stdHeader-hidden')} onMouseOut={() => setShowZones(false)}>
        {/* <div className="stdHeader-background"><img src={topRayures} /></div> */}

        <button
          className="stdHeader-handler"
          type="button"
          onClick={() => {
            setActResp(!actResp);
            setAct(true);
          }}
        ><img src={handler} alt="" />
        </button>


        <div className="stdHeader-logo">
          <ButtonClose onClick={() => setAct(false)} />
          <button type="button" onClick={() => history.push('/')}><img src={logo} alt="" /></button>
        </div>

        <nav className="stdHeader-menu">
          <ButtonClose onClick={() => setActResp(false)} />
          <button type="button" onClick={() => headerMoveToPanoScene('scene_lobby1')} className={clsx(currentPage === PAGE_LOBBY && currentSceneInPanorama && currentSceneInPanorama.search('scene_lobby') !== -1 && 'act')}>Lobby</button>
          <DropdowMenu
            attachAllRight={isMobile()}
            className={clsx(currentPage === PAGE_LOBBY && currentSceneInPanorama && currentSceneInPanorama.search('zone') !== -1 && 'act')}
            menu={(
              <div onMouseOver={() => setShowZones(true)}>
                <button type="button" onClick={() => headerMoveToPanoScene('scene_zone1_1')}>Experiences</button>
                <button type="button" onClick={() => headerMoveToPanoScene('scene_zone2_1')}>Partnership</button>
                <button type="button" onClick={() => headerMoveToPanoScene('scene_zone3_0')}>Connectivity</button>
              </div>
            )}
          >Zones
          </DropdowMenu>

          <button
            className={clsx(currentPage === PAGE_LIVE && 'act')}
            type="button"
            onClick={() => {
              setActResp(false);
              changePage(PAGE_LIVE);
            }}
          >Main stage
          </button>
          <a href="https://urldefense.com/v3/__https://www.kone.ae/en/connectfortomorrow.aspx?utm_source=DXWorld&utm_medium=Website&utm_campaign=KMEA-DX-World2021__;!!Gajz09w!WBzsgQuyGeaBjaONfM16y8GmyfsKYGvsPZ6huY3KXAr6wCQEh9iotKL0fr9Ds2CG7fWB0x4$" target="_blank" rel="noopener">Book meeting</a>
          <button
            className={clsx(currentPage === PAGE_FEEDBACK && 'act')}
            type="button"
            onClick={() => {
              setActResp(false);
              changePage(PAGE_FEEDBACK);
            }}
          >Feedback
          </button>
        </nav>

        <div className="stdHeader-right">
          <a target="_blank" rel="noreferer" href="https://www.facebook.com/KONEMiddleEast"><img src={fb} alt="fb" /></a>
          <a target="_blank" rel="noreferer" href="https://www.instagram.com/kone.mea/"><img src={insta} alt="insta" /></a>
          <a target="_blank" rel="noreferer" href="https://twitter.com/KONE_MEAfrica"><img src={twitter} alt="twitter" /></a>
          <a target="_blank" rel="noreferer" href="https://www.linkedin.com/company/5047"><img src={linkedin} alt="linkedin" /></a>
        </div>
      </header>
      { willLogin && <LoginPopup onClose={() => setWillLogin(false)} /> }

    </>
  );
};

export default Header;

export {
  PAGE_FEEDBACK, PAGE_LIVE, PAGE_LOBBY,
};
