import React from 'react';
import PropTypes from 'prop-types';
import Vimeo from '@u-wave/react-vimeo';
import lobby1 from '../../../img/KO/Slides/Lobby/lobby1.png';
import lobby_dxTrailerFilm1_2 from '../../../img/KO/Slides/Lobby/lobby_dxTrailerFilm1_2.png';
import lobby_dxInBrief2_1 from '../../../img/KO/Slides/Lobby/lobby_dxInBrief2_1.png';
import lobby_dxInBrief2_2 from '../../../img/KO/Slides/Lobby/lobby_dxInBrief2_2.png';
import lobby_timeline3 from '../../../img/KO/Slides/Lobby/lobby_timeline3.png';
import zone1_spot2_1 from '../../../img/KO/Slides/Zone1/zone1_spot2_1.png';
import zone1_spot2_2 from '../../../img/KO/Slides/Zone1/zone1_spot2_2.png';
import zone1_spot2_3 from '../../../img/KO/Slides/Zone1/zone1_spot2_3.png';
import zone1_spot21_2 from '../../../img/KO/Slides/Zone1/zone1_spot21_2.jpg';
import zone1_spot21_3 from '../../../img/KO/Slides/Zone1/zone1_spot21_3.png';
import zone1_spot22_2 from '../../../img/KO/Slides/Zone1/zone1_spot22_2.jpg';
import zone1_spot22_3 from '../../../img/KO/Slides/Zone1/zone1_spot22_3.png';
import zone1_spot23_2 from '../../../img/KO/Slides/Zone1/zone1_spot23_2.jpg';
import zone1_spot23_3 from '../../../img/KO/Slides/Zone1/zone1_spot23_3.png';
import zone1_spot3_1 from '../../../img/KO/Slides/Zone1/zone1_spot3_1.png';
import zone1_spot3_2_2 from '../../../img/KO/Slides/Zone1/zone1_spot3_2_2.png';
import zone1_spot3_2_3 from '../../../img/KO/Slides/Zone1/zone1_spot3_2_3.png';
import zone1_spot3_2_4 from '../../../img/KO/Slides/Zone1/zone1_spot3_2_4.png';
import zone1_spot3_2_5 from '../../../img/KO/Slides/Zone1/zone1_spot3_2_5.png';
import zone1_spot3_3_1 from '../../../img/KO/Slides/Zone1/zone1_spot3_3_1.png';
import zone1_spot3_3_2 from '../../../img/KO/Slides/Zone1/zone1_spot3_3_2.png';
import zone1_spot3_3_3 from '../../../img/KO/Slides/Zone1/zone1_spot3_3_3.png';
import zone1_spot3_4_1 from '../../../img/KO/Slides/Zone1/zone1_spot3_4_1.png';
import zone1_spot3_4_2 from '../../../img/KO/Slides/Zone1/zone1_spot3_4_2.png';
import zone1_spot4_1 from '../../../img/KO/Slides/Zone1/zone1_spot4_1.png';
import zone1_spot4_2 from '../../../img/KO/Slides/Zone1/zone1_spot4_2.png';
import zone2_spot1 from '../../../img/KO/Slides/Zone2/zone2_spot1.png';
import zone2_spot2_1 from '../../../img/KO/Slides/Zone2/zone2_spot2_1.png';
import zone2_spot2_2 from '../../../img/KO/Slides/Zone2/zone2_spot2_2.png';
import zone2_spot2_3 from '../../../img/KO/Slides/Zone2/zone2_spot2_3.png';
import zone2_spot2_4 from '../../../img/KO/Slides/Zone2/zone2_spot2_4.png';
import zone2_spot3_1 from '../../../img/KO/Slides/Zone2/zone2_spot3_1.png';
import zone2_spot3_2_1 from '../../../img/KO/Slides/Zone2/zone2_spot3_2_1.png';
import zone2_spot3_2_2 from '../../../img/KO/Slides/Zone2/zone2_spot3_2_2.png';
import zone2_spot3_2_3 from '../../../img/KO/Slides/Zone2/zone2_spot3_2_3.png';
import zone2_spot3_4_1 from '../../../img/KO/Slides/Zone2/zone2_spot3_4_1.png';
import zone2_spot3_4_2 from '../../../img/KO/Slides/Zone2/zone2_spot3_4_2.png';
import zone2_spot4_1 from '../../../img/KO/Slides/Zone2/zone2_spot4_1.png';
import zone2_spot4_2 from '../../../img/KO/Slides/Zone2/zone2_spot4_2.png';
import zone2_spot4_3 from '../../../img/KO/Slides/Zone2/zone2_spot4_3.png';
import zone2_spot5 from '../../../img/KO/Slides/Zone2/zone2_spot5.png';
import zone3_spot1_1 from '../../../img/KO/Slides/Zone3/zone3_spot1_1.png';
import zone3_spot1_3 from '../../../img/KO/Slides/Zone3/zone3_spot1_3.png';
import zone3_spot1_4 from '../../../img/KO/Slides/Zone3/zone3_spot1_4.png';
import zone3_spot2_1 from '../../../img/KO/Slides/Zone3/zone3_spot2_1.png';
import zone3_spot2_3_1 from '../../../img/KO/Slides/Zone3/zone3_spot2_3_1.jpg';
import zone3_spot2_3_2 from '../../../img/KO/Slides/Zone3/zone3_spot2_3_2.jpg';
import zone3_spot2_3_3 from '../../../img/KO/Slides/Zone3/zone3_spot2_3_3.jpg';
import zone3_spot2_3_4 from '../../../img/KO/Slides/Zone3/zone3_spot2_3_4.png';
import zone3_spot2_3_5 from '../../../img/KO/Slides/Zone3/zone3_spot2_3_5.png';
import zone3_spot2_4_1 from '../../../img/KO/Slides/Zone3/zone3_spot2_4_1.jpg';
import zone3_spot2_4_2 from '../../../img/KO/Slides/Zone3/zone3_spot2_4_2.png';
import zone3_spot2_4_3 from '../../../img/KO/Slides/Zone3/zone3_spot2_4_3.jpg';
import zone3_spot2_4_4 from '../../../img/KO/Slides/Zone3/zone3_spot2_4_4.png';
import zone3_spot2_4_5 from '../../../img/KO/Slides/Zone3/zone3_spot2_4_5.jpg';
import zone3_spot3_1 from '../../../img/KO/Slides/Zone3/zone3_spot3_1.png';
import zone3_spot3_3 from '../../../img/KO/Slides/Zone3/zone3_spot3_3.png';
import zone3_spot3_4 from '../../../img/KO/Slides/Zone3/zone3_spot3_4.jpg';
import zone3_spot4_1 from '../../../img/KO/Slides/Zone3/zone3_spot4_1.png';
import zone3_spot4_2 from '../../../img/KO/Slides/Zone3/zone3_spot4_2.jpg';
import zone3_spot5 from '../../../img/KO/Slides/Zone3/zone3_spot5.png';

import {
  PANORAMA_PAGE_LOBBY_DXINBRIEF2,
  PANORAMA_PAGE_LOBBY_DXTRAILERFILM1, PANORAMA_PAGE_LOBBY_DXTRAILERFILM4, PANORAMA_PAGE_LOBBY_LOBBY1,
  PANORAMA_PAGE_LOBBY_TIMELINE3, PANORAMA_PAGE_ZONE1_SPOT1, PANORAMA_PAGE_ZONE1_SPOT2, PANORAMA_PAGE_ZONE1_SPOT21, PANORAMA_PAGE_ZONE1_SPOT22, PANORAMA_PAGE_ZONE1_SPOT23,
  PANORAMA_PAGE_ZONE1_SPOT3,
  PANORAMA_PAGE_ZONE1_SPOT4,
  PANORAMA_PAGE_ZONE2_SPOT1,
  PANORAMA_PAGE_ZONE2_SPOT2,
  PANORAMA_PAGE_ZONE2_SPOT3,
  PANORAMA_PAGE_ZONE2_SPOT4,
  PANORAMA_PAGE_ZONE2_SPOT5,
  PANORAMA_PAGE_ZONE3_SPOT1,
  PANORAMA_PAGE_ZONE3_SPOT2,
  PANORAMA_PAGE_ZONE3_SPOT3,
  PANORAMA_PAGE_ZONE3_SPOT4,
  PANORAMA_PAGE_ZONE3_SPOT5,
} from '../Panorama';
import KoneSlideshow, { KoneSlideshowPage, KoneSlideshowVerticalScroll } from './Kone/KoneSlideshow';
import Video from '../../components/UI/Video';

const KoneTopPanoramaPage = ({
  currentTopPanoramaPage, onClose,
}) => {
  if (![
    PANORAMA_PAGE_LOBBY_LOBBY1,
    PANORAMA_PAGE_LOBBY_DXTRAILERFILM1,
    PANORAMA_PAGE_LOBBY_DXINBRIEF2,
    PANORAMA_PAGE_LOBBY_TIMELINE3,
    PANORAMA_PAGE_LOBBY_DXTRAILERFILM4,
    PANORAMA_PAGE_ZONE1_SPOT1,
    PANORAMA_PAGE_ZONE1_SPOT2,
    PANORAMA_PAGE_ZONE1_SPOT21,
    PANORAMA_PAGE_ZONE1_SPOT22,
    PANORAMA_PAGE_ZONE1_SPOT23,
    PANORAMA_PAGE_ZONE1_SPOT3,
    PANORAMA_PAGE_ZONE1_SPOT4,
    PANORAMA_PAGE_ZONE2_SPOT1,
    PANORAMA_PAGE_ZONE2_SPOT2,
    PANORAMA_PAGE_ZONE2_SPOT3,
    PANORAMA_PAGE_ZONE2_SPOT4,
    PANORAMA_PAGE_ZONE2_SPOT5,
    PANORAMA_PAGE_ZONE3_SPOT1,
    PANORAMA_PAGE_ZONE3_SPOT2,
    PANORAMA_PAGE_ZONE3_SPOT3,
    PANORAMA_PAGE_ZONE3_SPOT4,
    PANORAMA_PAGE_ZONE3_SPOT5,
  ].includes(currentTopPanoramaPage)) {
    return null;
  }

  return (

    <>
      {(() => {
        switch (currentTopPanoramaPage) {
          case PANORAMA_PAGE_LOBBY_LOBBY1: {
            return (
              <KoneSlideshow onClose={onClose}>
                <KoneSlideshowPage type="img"><img src={lobby1} /></KoneSlideshowPage>
              </KoneSlideshow>
            );
          }
          case PANORAMA_PAGE_LOBBY_DXTRAILERFILM1: {
            return (
              <KoneSlideshow onClose={onClose}>
                <KoneSlideshowPage variant="transparent" type="video"><Video url="https://player.vimeo.com/video/564481579" autoPlay /></KoneSlideshowPage>
                <KoneSlideshowPage type="img"><img src={lobby_dxTrailerFilm1_2} /></KoneSlideshowPage>
              </KoneSlideshow>
            );
          }
          case PANORAMA_PAGE_LOBBY_DXINBRIEF2: {
            return (
              <KoneSlideshow onClose={onClose}>
                <KoneSlideshowPage type="img" variant="leftWhite"><img src={lobby_dxInBrief2_1} /></KoneSlideshowPage>
                <KoneSlideshowPage type="img"><img src={lobby_dxInBrief2_2} /></KoneSlideshowPage>
              </KoneSlideshow>
            );
          }
          case PANORAMA_PAGE_LOBBY_TIMELINE3: {
            return (
              <KoneSlideshow onClose={onClose}>
                <KoneSlideshowPage type="img"><img src={lobby_timeline3} /></KoneSlideshowPage>
              </KoneSlideshow>
            );
          }
          case PANORAMA_PAGE_LOBBY_DXTRAILERFILM4: {
            return (
              <KoneSlideshow onClose={onClose}>
                <KoneSlideshowPage variant="transparent" type="video"><Video url="https://player.vimeo.com/video/564750929" autoPlay /></KoneSlideshowPage>
              </KoneSlideshow>
            );
          }
          case PANORAMA_PAGE_ZONE1_SPOT1: {
            return (
              <KoneSlideshow onClose={onClose}>
                <KoneSlideshowPage variant="transparent" type="video"><Video url="https://player.vimeo.com/video/564313855" autoPlay /></KoneSlideshowPage>
              </KoneSlideshow>
            );
          }
          case PANORAMA_PAGE_ZONE1_SPOT2: {
            return (
              <KoneSlideshow onClose={onClose}>
                <KoneSlideshowPage type="img" variant="leftWhite"><img src={zone1_spot2_1} /></KoneSlideshowPage>
                <KoneSlideshowPage type="img"><img src={zone1_spot2_2} /></KoneSlideshowPage>
                <KoneSlideshowPage type="img"><img src={zone1_spot2_3} /></KoneSlideshowPage>
              </KoneSlideshow>
            );
          }
          case PANORAMA_PAGE_ZONE1_SPOT21: {
            return (
              <KoneSlideshow onClose={onClose}>
                <KoneSlideshowPage variant="transparent" type="video"><Video url="https://player.vimeo.com/video/564505262" /></KoneSlideshowPage>
                <KoneSlideshowPage type="img" variant="transparent"><img src={zone1_spot21_2} /></KoneSlideshowPage>
                <KoneSlideshowPage type="img" variant="imgAttachLeft"><img src={zone1_spot21_3} /></KoneSlideshowPage>
              </KoneSlideshow>
            );
          }
          case PANORAMA_PAGE_ZONE1_SPOT22: {
            return (
              <KoneSlideshow onClose={onClose}>
                <KoneSlideshowPage variant="transparent" type="video"><Video url="https://player.vimeo.com/video/564508804" /></KoneSlideshowPage>
                <KoneSlideshowPage type="img" variant="transparent"><img src={zone1_spot22_2} /></KoneSlideshowPage>
                <KoneSlideshowPage type="img" variant="imgAttachLeft"><img src={zone1_spot22_3} /></KoneSlideshowPage>
              </KoneSlideshow>
            );
          }
          case PANORAMA_PAGE_ZONE1_SPOT23: {
            return (
              <KoneSlideshow onClose={onClose}>
                <KoneSlideshowPage variant="transparent" type="video"><Video url="https://player.vimeo.com/video/564510877" /></KoneSlideshowPage>
                <KoneSlideshowPage type="img" variant="transparent"><img src={zone1_spot23_2} /></KoneSlideshowPage>
                <KoneSlideshowPage type="img" variant="imgAttachLeft"><img src={zone1_spot23_3} /></KoneSlideshowPage>
              </KoneSlideshow>
            );
          }
          case PANORAMA_PAGE_ZONE1_SPOT3: {
            return (
              <KoneSlideshow onClose={onClose}>
                <KoneSlideshowPage type="img" variant="leftWhite"><img src={zone1_spot3_1} /></KoneSlideshowPage>
                <KoneSlideshowVerticalScroll>
                  <KoneSlideshowPage type="video"><Video url="https://player.vimeo.com/video/564821872" /></KoneSlideshowPage>
                  <KoneSlideshowPage type="img"><img src={zone1_spot3_2_2} /></KoneSlideshowPage>
                  <KoneSlideshowPage type="img" variant="imgAttachRight"><img src={zone1_spot3_2_3} /></KoneSlideshowPage>
                  <KoneSlideshowPage type="img" variant="imgAttachLeft"><img src={zone1_spot3_2_4} /></KoneSlideshowPage>
                  <KoneSlideshowPage type="img" variant="imgAttachRight"><img src={zone1_spot3_2_5} /></KoneSlideshowPage>
                  <KoneSlideshowPage variant="transparent" type="video"><Video url="https://player.vimeo.com/video/564574482" /></KoneSlideshowPage>
                </KoneSlideshowVerticalScroll>
                <KoneSlideshowVerticalScroll>
                  <KoneSlideshowPage type="img" variant="imgAttachRight"><img src={zone1_spot3_3_1} /></KoneSlideshowPage>
                  <KoneSlideshowPage type="img" variant="imgAttachLeft"><img src={zone1_spot3_3_2} /></KoneSlideshowPage>
                  <KoneSlideshowPage type="img" variant="imgAttachLeft"><img src={zone1_spot3_3_3} /></KoneSlideshowPage>
                </KoneSlideshowVerticalScroll>
                <KoneSlideshowVerticalScroll>
                  <KoneSlideshowPage type="img" variant="imgAttachRight"><img src={zone1_spot3_4_1} /></KoneSlideshowPage>
                  <KoneSlideshowPage type="img" variant="imgAttachLeft"><img src={zone1_spot3_4_2} /></KoneSlideshowPage>
                </KoneSlideshowVerticalScroll>
              </KoneSlideshow>
            );
          }
          case PANORAMA_PAGE_ZONE1_SPOT4: {
            return (
              <KoneSlideshow onClose={onClose}>
                <KoneSlideshowPage type="img" variant="leftWhite"><img src={zone1_spot4_1} /></KoneSlideshowPage>
                <KoneSlideshowPage type="img" variant="imgAttachRight"><img src={zone1_spot4_2} /></KoneSlideshowPage>
              </KoneSlideshow>
            );
          }
          case PANORAMA_PAGE_ZONE2_SPOT1: {
            return (
              <KoneSlideshow onClose={onClose}>
                <KoneSlideshowPage type="img" variant="leftWhite"><img src={zone2_spot1} /></KoneSlideshowPage>
                <KoneSlideshowPage type="video"><Video url="https://player.vimeo.com/video/564583451" /></KoneSlideshowPage>
              </KoneSlideshow>
            );
          }
          case PANORAMA_PAGE_ZONE2_SPOT2: {
            return (
              <KoneSlideshow onClose={onClose}>
                <KoneSlideshowPage type="img" variant="leftWhite"><img src={zone2_spot2_1} /></KoneSlideshowPage>
                <KoneSlideshowPage type="img"><img src={zone2_spot2_2} /></KoneSlideshowPage>
                <KoneSlideshowPage type="img"><img src={zone2_spot2_3} /></KoneSlideshowPage>
                <KoneSlideshowPage type="img"><img src={zone2_spot2_4} /></KoneSlideshowPage>
              </KoneSlideshow>
            );
          }

          case PANORAMA_PAGE_ZONE2_SPOT3: {
            return (
              <KoneSlideshow onClose={onClose}>
                <KoneSlideshowPage type="img" variant="leftWhite"><img src={zone2_spot3_1} /></KoneSlideshowPage>

                <KoneSlideshowVerticalScroll>
                  <KoneSlideshowPage type="img"><img src={zone2_spot3_2_1} /></KoneSlideshowPage>
                  <KoneSlideshowPage type="img"><img src={zone2_spot3_2_2} /></KoneSlideshowPage>
                  <KoneSlideshowPage type="img"><img src={zone2_spot3_2_3} /></KoneSlideshowPage>
                </KoneSlideshowVerticalScroll>

                <KoneSlideshowPage type="video" variant="white"><Video url="https://player.vimeo.com/video/564600298" /></KoneSlideshowPage>

                <KoneSlideshowVerticalScroll>
                  <KoneSlideshowPage type="img" variant="imgAttachRight"><img src={zone2_spot3_4_1} /></KoneSlideshowPage>
                  <KoneSlideshowPage type="img" variant="imgAttachLeft"><img src={zone2_spot3_4_2} /></KoneSlideshowPage>
                </KoneSlideshowVerticalScroll>

              </KoneSlideshow>
            );
          }
          case PANORAMA_PAGE_ZONE2_SPOT4: {
            return (
              <KoneSlideshow onClose={onClose}>
                <KoneSlideshowPage type="img" variant="leftWhite"><img src={zone2_spot4_1} /></KoneSlideshowPage>
                <KoneSlideshowPage type="img" variant="imgAttachLeft"><img src={zone2_spot4_2} /></KoneSlideshowPage>
                <KoneSlideshowPage type="img" variant="leftWhite"><img src={zone2_spot4_3} /></KoneSlideshowPage>
              </KoneSlideshow>
            );
          }
          case PANORAMA_PAGE_ZONE2_SPOT5: {
            return (
              <KoneSlideshow onClose={onClose}>
                <KoneSlideshowPage type="img" variant="leftWhite"><img src={zone2_spot5} /></KoneSlideshowPage>
                <KoneSlideshowPage type="video"><Video url="https://player.vimeo.com/video/564933405" /></KoneSlideshowPage>
              </KoneSlideshow>
            );
          }
          case PANORAMA_PAGE_ZONE3_SPOT1: {
            return (
              <KoneSlideshow onClose={onClose}>
                <KoneSlideshowPage type="img" variant="leftWhite"><img src={zone3_spot1_1} /></KoneSlideshowPage>
                <KoneSlideshowPage type="video"><Video url="https://player.vimeo.com/video/564823644" /></KoneSlideshowPage>
                <KoneSlideshowPage type="img" variant=""><img src={zone3_spot1_3} /></KoneSlideshowPage>
                <KoneSlideshowPage type="img" variant="leftWhite"><img src={zone3_spot1_4} /></KoneSlideshowPage>
              </KoneSlideshow>
            );
          }
          case PANORAMA_PAGE_ZONE3_SPOT2: {
            return (
              <KoneSlideshow onClose={onClose}>
                <KoneSlideshowPage type="img" variant="leftWhite"><img src={zone3_spot2_1} /></KoneSlideshowPage>
                <KoneSlideshowPage type="video"><Video url="https://player.vimeo.com/video/564934142" /></KoneSlideshowPage>

                <KoneSlideshowVerticalScroll>
                  <KoneSlideshowPage type="img" variant="transparent"><img src={zone3_spot2_3_1} /></KoneSlideshowPage>
                  <KoneSlideshowPage type="img" variant="transparent"><img src={zone3_spot2_3_2} /></KoneSlideshowPage>
                  <KoneSlideshowPage type="img" variant="transparent"><img src={zone3_spot2_3_3} /></KoneSlideshowPage>
                  <KoneSlideshowPage type="img" variant=""><img src={zone3_spot2_3_4} /></KoneSlideshowPage>
                  <KoneSlideshowPage type="img" variant="imgAttachLeft"><img src={zone3_spot2_3_5} /></KoneSlideshowPage>
                </KoneSlideshowVerticalScroll>

                <KoneSlideshowVerticalScroll>
                  <KoneSlideshowPage type="img" variant="transparent"><img src={zone3_spot2_4_1} /></KoneSlideshowPage>
                  <KoneSlideshowPage type="img" variant="imgAttachLeft"><img src={zone3_spot2_4_2} /></KoneSlideshowPage>
                  <KoneSlideshowPage type="img"><img src={zone3_spot2_4_3} /></KoneSlideshowPage>
                  <KoneSlideshowPage type="img"><img src={zone3_spot2_4_4} /></KoneSlideshowPage>
                  <KoneSlideshowPage type="img"><img src={zone3_spot2_4_5} /></KoneSlideshowPage>
                </KoneSlideshowVerticalScroll>

              </KoneSlideshow>
            );
          }

          case PANORAMA_PAGE_ZONE3_SPOT3: {
            return (
              <KoneSlideshow onClose={onClose}>
                <KoneSlideshowPage type="img" variant="leftWhite"><img src={zone3_spot3_1} /></KoneSlideshowPage>
                <KoneSlideshowPage type="video"><Video url="https://player.vimeo.com/video/564947862" /></KoneSlideshowPage>
                <KoneSlideshowPage type="img" variant="imgAttachLeft"><img src={zone3_spot3_3} /></KoneSlideshowPage>
                <KoneSlideshowPage type="img" variant="leftWhite"><img src={zone3_spot3_4} /></KoneSlideshowPage>
              </KoneSlideshow>
            );
          }
          case PANORAMA_PAGE_ZONE3_SPOT4: {
            return (
              <KoneSlideshow onClose={onClose}>
                <KoneSlideshowPage type="img" variant="leftWhite"><img src={zone3_spot4_1} /></KoneSlideshowPage>
                <KoneSlideshowPage type="img" variant="backWhite"><img src={zone3_spot4_2} /></KoneSlideshowPage>
                <KoneSlideshowPage type="video"><Video url="https://player.vimeo.com/video/564948086" /></KoneSlideshowPage>
                <KoneSlideshowPage type="video"><Video url="https://player.vimeo.com/video/564948231" /></KoneSlideshowPage>
              </KoneSlideshow>
            );
          }
          case PANORAMA_PAGE_ZONE3_SPOT5: {
            return (
              <KoneSlideshow onClose={onClose}>
                <KoneSlideshowPage type="img" variant="leftWhite"><img src={zone3_spot5} /></KoneSlideshowPage>
                <KoneSlideshowPage type="video"><Video autoPlay url="https://player.vimeo.com/video/564951621" /></KoneSlideshowPage>
              </KoneSlideshow>
            );
          }
          default: {
            break;
          }
        }
      })()}
    </>

  );
};

KoneTopPanoramaPage.propTypes = {
  currentTopPanoramaPage: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

KoneTopPanoramaPage.defaultProps = {
};

export default KoneTopPanoramaPage;
