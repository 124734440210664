import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const SelectPannel = ({
  options, value, onChooseOption, pannelRef, ...props
}) => {
  const isValueSelected = (testVal) => {
    if (Array.isArray(value)) {
      return value.includes(testVal);
    }
    return value === testVal;
  };

  return (
    <ul className="stdSelect-pannel" ref={pannelRef}>
      {options.map((option, oIndex) => <li className={clsx(isValueSelected(option.value) && 'stdSelect-pannel-selected')} key={option.index || oIndex}><button type="button" onClick={() => onChooseOption(option.value)}>{option.label}</button></li>)}
    </ul>


  );
};


SelectPannel.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onChooseOption: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  pannelRef: PropTypes.object.isRequired,
};

SelectPannel.defaultProps = {
  value: [],
};
export default SelectPannel;
