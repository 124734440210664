import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import PublicChat from '../StaffSpace/PublicChat';
import Button from '../../components/UI/Button';
import Video from '../../components/UI/Video';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import MenuLang, { LANG_EN } from '../../components/UI/MenuLang';
import SmallProgram from '../Program/SmallProgram';
import usePubsub from '../../Lib/usePubsub';
import startPlayer, { stopPlayer, switchLanguage } from '../../Lib/misc/startNanoPlayer';

const Live = () => {
  const [lang, setLang] = useState(LANG_EN);
  const container = useRef();
  const [isFullScreenState, setIsFullScreenState] = useState(false);

  const exitHandler = () => {
    if (isFullScreenState && (document.fullScreen || document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement !== null)) {
      setIsFullScreenState(false);
    }
  };

  const isFullScreen = () => document.fullScreen
    || document.webkitIsFullScreen
    || document.mozfullScreen
    || document.msFullscreenElement;

  const goFullScreen = () => {
    if (isFullScreen()) {
      setIsFullScreenState(false);
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozExitFullScreen) {
        document.mozExitFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      // if we are in non-fullscreen mode, open it
    } else if (container.current) {
      setIsFullScreenState(true);
      if (container.current.requestFullscreen) {
        container.current.requestFullscreen();
      } else if (container.current.webkitRequestFullscreen) {
        window.document.documentElement.webkitRequestFullscreen();
        // container.current.webkitRequestFullscreen();
      } else if (container.current.mozRequestFullScreen) {
        container.current.mozRequestFullScreen();
      } else if (container.current.msRequestFullscreen) {
        container.current.msRequestFullscreen();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', exitHandler, false);
    document.addEventListener('mozfullscreenchange', exitHandler, false);
    document.addEventListener('MSFullscreenChange', exitHandler, false);
    document.addEventListener('webkitfullscreenchange', exitHandler, false);
    return () => {
      document.removeEventListener('fullscreenchange', exitHandler, false);
      document.removeEventListener('mozfullscreenchange', exitHandler, false);
      document.removeEventListener('MSFullscreenChange', exitHandler, false);
      document.removeEventListener('webkitfullscreenchange', exitHandler, false);
    };
  });

  return (
    <>
      <div className={clsx('component-live-iframe', isFullScreenState && 'component-live-iframe-fullScreen')} ref={container}>
        {/* <Button small onClick={goFullScreen} variant="transparent">{ isFullScreen() ? <>Exit fullscreen</> : <>Fullscreen</>}</Button> */}

        <iframe src="https://vimeo.com/event/1108950/embed" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />

        {/*<MenuLang val={lang} setLang={setLang} textVersion />*/}
        {/*{ lang === 'EN' ? (*/}
        {/*  <iframe src="https://vimeo.com/event/1108950/embed" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />*/}
        {/*) : (*/}
        {/*  <iframe src="https://vimeo.com/event/1074516/embed/5555c7af28" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />*/}
        {/*)}*/}

      </div>
    </>
  );
};

const LiveNanoPlayer = () => {
  const container = useRef();

  useEffect(() => {
    startPlayer('playerDiv', 0);

    return () => {
      stopPlayer();
    };
  }, []);

  return (
    <div className={clsx('component-live-iframe')}>
      <div id="playerDiv" ref={container} />
      <div id="languageButtons">
        <button id="btnEN" onClick={() => switchLanguage(0)}>EN</button>
        <button id="btnAR" onClick={() => switchLanguage(1)}>AR</button>
        <button id="btnCH" onClick={() => switchLanguage(2)}>CH</button>
        <button id="btnFR" onClick={() => switchLanguage(3)}>FR</button>
      </div>
    </div>
  );
};

const LiveWithChat = ({ showProgram }) => {
  const config = useSelector((state) => state.stateDataReducer.config);
  const isFinished = config && parseInt(config.isFinished) === 1;

  usePubsub('refresh', () => {
    document.location.reload();
  });

  const goToReplay = () => {
    window.flash({ action: 'replay' });
  };

  if (isFinished) {
    return (
      <div className="component-live-finished">
        { config.endVideoUrl && <Video url={config.endVideoUrl} /> }
        {/* <div style={{ */}
        {/*  display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1rem', */}
        {/* }} */}
        {/* > */}
        {/*  <Button onClick={goToReplay}>Access to the replay</Button> */}
        {/* </div> */}
      </div>
    );
  }

  return (
    <div className={clsx('component-live', showProgram && 'component-live-showProgram')}>
      <ErrorBoundary><Live /> </ErrorBoundary>
      {/*<ErrorBoundary><PublicChat withSondage tabbed /> </ErrorBoundary>*/}
    </div>
  );
};

export { LiveWithChat };
export default Live;
