import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const Input = ({
  hasError, isTouched, variant, className, onChange, value, ...props
}) => (
  <input
    {...props}
    value={value}
    onChange={(e) => onChange(e.target.value)}
    className={clsx(
      'stdInput',
      className,
      variant && variant.split(' ').map((el) => `stdInput-${el}`),
      hasError && isTouched && 'stdInput-error',
      !hasError && isTouched && 'stdInput-correct',
    )}
  />
);

Input.propTypes = {
  hasError: PropTypes.bool,
  isTouched: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  variant: PropTypes.string,
  value: PropTypes.string,
};

Input.defaultProps = {
  className: '',
  hasError: false,
  isTouched: false,
  variant: null,
  value: '',
};
export default Input;
