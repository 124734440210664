import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import Popup from '../components/UI/Popup/Popup';
import Fieldset from '../components/UI/Form/Fieldset';
import Button from '../components/UI/Button';
import InputRate from '../components/UI/Form/InputRate';
import useInterface from '../Lib/useInterface';
import * as StateAction from "../Redux/actions/State";

const PopupFeedback = ({ onClose }) => {
  const email = useSelector((state) => state.interfaceDataReducer.email);
  const hasFeedbacked = useSelector((state) => state.stateDataReducer.hasFeedbacked);
  const { sendMessage } = useInterface();
  const dispatch = useDispatch();


  return (
    <Popup onClosePopup={onClose}>
      <div className="component-popupFeedback">
        { hasFeedbacked !== 1 ? (
          <><div className="component-popupFeedback-titre">Rate your experience so far</div>

            <Formik
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  await axios.post('kone-feedback', {
                    ...values,
                    email,
                  });
                  sendMessage('Your rating has been received');
                  dispatch({ type: StateAction.SET_FEEDBACKED });
                  setSubmitting(false);
                } catch (err) {
                  setSubmitting(false);
                }
              }}
              initialValues={{
                feedbackA: null,
                feedbackB: null,
                feedbackC: null,
                feedbackD: null,
              }}
              validationSchema={Yup.object().shape({
                feedbackA: Yup.number().required('Please rate').typeError('Please provide a rate'),
                feedbackB: Yup.number().required('Please rate').typeError('Please provide a rate'),
                feedbackC: Yup.number().required('Please rate').typeError('Please provide a rate'),
                feedbackD: Yup.number().required('Please rate').typeError('Please provide a rate'),
              })}
            >

              {({
                values, setFieldValue, handleSubmit, isSubmitting,
              }) => (
                <Form className="stdForm form-100 form-center center">
                  <div className="midMargin-y">
                    <Fieldset name="feedbackA" input={(othersProps) => <InputRate {...othersProps} />}>a. Overall experience?</Fieldset>
                    <Fieldset name="feedbackB" input={(othersProps) => <InputRate {...othersProps} />}>b. Overall flow and speakers?</Fieldset>
                    <Fieldset name="feedbackC" input={(othersProps) => <InputRate {...othersProps} />}>c. Ease of access and navigation?</Fieldset>
                    <Fieldset name="feedbackD" input={(othersProps) => <InputRate {...othersProps} />}>d. Value of our content to you?</Fieldset>
                  </div>
                  <br />
                  <Button type="submit" className="stdBtn">Submit</Button>
                </Form>
              )}
            </Formik>
          </>
        ) : (
          <div className="component-popupFeedback-finish">
            <div className="giant">Thank you</div>
            for your feedback!
          </div>
        ) }
      </div>

    </Popup>
  );
};

PopupFeedback.propTypes = {
  onClose: PropTypes.func.isRequired,
};

PopupFeedback.defaultProps = {
};

export default PopupFeedback;
