import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { range } from 'lodash';
import flecheDroite from '../../../img/KO/flecheDroite.svg';
import flecheDroiteBlanche from '../../../img/KO/flecheDroiteBlanche.svg';
import getWindowSize from '../../Lib/misc/DOM/getWindowSize';

const Carousel = ({
  children, className, count, withIndicators, onChangePage, startAt, dontComeBack, variant, ...props
}) => {
  const [curPage, setCurPage] = useState(startAt);

  let correctedChildren = children;
  if (!Array.isArray(correctedChildren)) {
    correctedChildren = [correctedChildren];
  }
  const getCurrentCount = () => {
    switch (count) {
      case 5: {
        if (getWindowSize().width <= 480) {
          return 1;
        }
        if (getWindowSize().width <= 820) {
          return 2;
        }
        return count;
      }
      default:
        return count;
    }
  };

  const getNbPage = () => correctedChildren.length / getCurrentCount();

  const setPage = (newPage) => {
    let correctedNewPage = newPage;
    if (correctedNewPage >= getNbPage()) {
      correctedNewPage = 0;
    }
    if (correctedNewPage < 0) {
      correctedNewPage = getNbPage() - 1;
    }
    setCurPage(correctedNewPage);
    if (onChangePage) {
      onChangePage(newPage);
    }
  };

  const isScrollable = () => correctedChildren.length / getCurrentCount() > 1;

  const left = `calc(-${curPage * 100}% - ${curPage}rem)`;

  return (
    <div
      className={clsx(
        'stdCarousel',
        `stdCarousel-${count}`,
        withIndicators && 'stdCarousel-withIndicators',
        className,
        variant && `stdCarousel-${variant}`,
      )}
      {...props}
    >

      <button className={clsx(dontComeBack && (!isScrollable() || curPage === 0) && 'faded')} disabled={!isScrollable()} type="button" onClick={() => setPage(curPage - 1)}><img src={flecheDroite} /></button>
      <div className="stdCarousel-container">
        <div style={{ left }}>
          {correctedChildren.map((child, index) => <div className="stdCarousel-el" key={index}>{child}</div>)}
        </div>
      </div>
      <button className={clsx(dontComeBack && (!isScrollable() || curPage === getNbPage() - 1) && 'faded')} disabled={!isScrollable()} type="button" onClick={() => setPage(curPage + 1)}><img src={flecheDroite} /></button>

      {withIndicators && (
      <div className="stdCarousel-indicators">
        {range(getNbPage()).map((key) => (
          <button
            type="button"
            className={clsx(key === curPage && 'act')}
            onClick={() => setCurPage(key)}
          />
        ))}

      </div>
      )}
    </div>
  );
};

Carousel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  variant: PropTypes.string,
  count: PropTypes.number,
  startAt: PropTypes.number,
  withIndicators: PropTypes.bool,
  dontComeBack: PropTypes.bool,
  onChangePage: PropTypes.func,
};

Carousel.defaultProps = {
  withIndicators: true,
  dontComeBack: false,
  className: null,
  variant: null,
  onChangePage: null,
  count: 6,
  startAt: 0,
};

export default Carousel;
